import React, { useEffect, useState } from 'react';
import { eOrderStatus, GetEnumName } from './enum';
import { timestampTOstring } from '../../../Database/validation';

export default function Accordian({ order, Index, SelectedIndex, onclick }) {
    const [IsOpen, setIsOpen] = useState(false);
    const [StatusClass, setStatusClass] = useState('');

    useEffect(() => {
        if (order && order.order_status) {
            switch (order.order_status) {
                case eOrderStatus.Pending:
                    setStatusClass('border-gray-500 text-gray-500');
                    break;

                case eOrderStatus.Processing:
                    setStatusClass('border-yellow-500 text-yellow-500');
                    break;

                case eOrderStatus.Shipping:
                    setStatusClass('border-blue-500 text-blue-500');
                    break;

                case eOrderStatus.Complete:
                    setStatusClass('border-green-500 text-green-500');
                    break;

                case eOrderStatus.Cancel:
                    setStatusClass('border-red-500 text-red-500');
                    break;

                default:
                    setStatusClass('');
                    break;
            }

        }
    }, [order.order_status]);

    const loadProducts = () => {
        return order ? order.item.map((cart, index) => {
            // let objMaterial = lstMaterial.find(material => material.id === cart.material_id);
            // let objPurity = lstPurity.find(material => material.id === cart.karat_id);
            // let objDiamond = lstDiamond.find(material => material.id === cart.diamond_id);

            return (<li className="py-6 sm:py-10 list-none" key={cart.id}>
                <div className="flex-shrink-0">
                    <img src={cart.image_url} alt="Front of men&#039;s Basic Tee in black." className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48" />
                </div>

                <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div className="relative pr-9">
                        <div className='mb-4'>
                            <div className="flex justify-between">
                                <h3 className="text-sm">
                                    <a href="#" className="font-medium text-gray-700 hover:text-gray-800">
                                        {cart.name}
                                    </a>
                                </h3>
                            </div>
                            <div className="mt-1 mb-1 flex text-sm">
                                {/* <p className="text-gray-500">{objMaterial?.Material}{objPurity && '(' + objPurity.Karat + 'k)'}</p> */}
                                <p style={{ whiteSpace: 'pre-line' }} className="text-gray-500">{cart?.description.replace('\\n ', '')}</p>
                                {/* {objDiamond && (<div className="ml-4 border-l border-gray-200 pl-4">
                                    <p className="text-gray-500">Diamond : {objDiamond.Size} CT</p>
                                </div>)} */}
                            </div>
                            <p className="text-sm font-medium text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                                ${cart.price}.00
                            </p>
                        </div>

                        <div className="mt-4 sm:mt-0 sm:pr-9">
                            <label>
                                Quantity : {cart.quantity}
                            </label>
                        </div>
                    </div>
                </div>
            </li>)
        }) : '';
    }

    return (
        <>
            <div className={`flex ${SelectedIndex == Index ? 'grid-row-selected' : ''}`} onClick={() => { onclick(Index) }}>
                <label className='p-3 w-20 text-center'>{Index + 1}</label>
                <label className='p-3 w-96'>{order?.customer?.name ?? ''}</label>
                <label className='p-3 w-28'>${order?.payment?.Amount ?? '0.00'}</label>
                <label className='p-3 w-36 text-center'><span className={`border-2 px-3 py-1 rounded-3xl ${StatusClass}`}>{GetEnumName(eOrderStatus, order?.order_status ?? 1)}</span></label>
                <label className='p-3 w-64'>{order && order.order_time && timestampTOstring(order.order_time)}</label>
                <div className='p-3 w-5'>
                    <button type="button" className="mr-4 items-center justify-between font-medium rtl:text-right text-gray-500 border-gray-200 border-gray-700 text-gray-400 gap-3">
                        <svg onClick={() => setIsOpen(!IsOpen)} data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                        </svg>
                    </button>
                </div>
            </div>
            <ui className={`${IsOpen ? '' : 'hidden'} delay-300 grid grid-cold-1 sm:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4`}>
                {loadProducts()}
            </ui>
        </>
    )
}
