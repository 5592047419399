import React from 'react'

export default function Refund_return_policy() {
    return (
        <div className='lg:p-24 md:p-20 sm:p-14 p-10 text-justify'>
            <p className='mt-3'>At All-Jewelz, we strive to provide you with the best possible experience. We carefully prepare and package your order to ensure its freshness and quality. However, if you receive a damaged or incorrectly shipped item, we are here to assist you.</p>

            <h1 className='text-xl text-pink-600 mt-5 mb-3'>Damaged or Incorrectly Shipped Items</h1>
            <p className='mt-3'>If you receive a damaged or incorrectly shipped item, please notify us within 24 hours of delivery. Provide us with a picture of the damaged item, and we will gladly assist you in resolving the issue. Customer will have to arrange for the return of the item, and upon receipt and inspection, we will refund the value of the damaged item, including any shipping fees, as store credit for future purchases.</p>

            <h1 className='text-xl text-pink-600 mt-5 mb-3'>Cancellation Policy</h1>
            <p className='mt-3'>You may cancel your order within 1 day of placement. If the order has not been prepared, we will refund the amount to the bank account provided during the order process. However, if the order has been prepared, cancellations are not possible as we prepare all orders freshly to ensure the highest possible quality.</p>

            <h1 className='text-xl text-pink-600 mt-5 mb-3'>Return Policy</h1>
            <p className='mt-3'>Apart from damaged or incorrectly shipped items, we are unable to accept returns or exchanges. We take utmost care in preparing your order fresh, we cannot accept returns on any skincare products.</p>
            <p className='mt-3'>We appreciate your understanding and cooperation in adhering to our refund and return policy. If you have any further questions or concerns, please reach out to our customer support team. Your satisfaction is our top priority.</p>
        </div>
    )
}
