import React from 'react'
import { Link } from 'react-router-dom'

export default function PageTitle({Name}) {
    return (
        <div id="page-title" className="pls-page-title pls-page-title-center title-size-default color-scheme-default" style={{ paddingTop: '95px', paddingBottom: '95px', backgroundImage: 'url(https://alukas.presslayouts.com/wp-content/uploads/2023/03/shop-page-title-bg.png)', backgroundSize: 'cover' }}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="entry-header">
                            <h1 className="title text-2xl">{Name}</h1>
                        </div>
                        <div className="entry-breadcrumbs mt-3">
                            <nav className="pls-breadcrumb">
                                <Link className='hover:text-yellow-600' to="/">Home</Link>
                                <i className="fa fa-angle-right"></i>
                                <span className="last">{Name}</span>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
