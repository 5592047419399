import React, { useEffect, useState } from 'react'

import { GetSelectedItem, SelectRow } from '../../../Database/helper'
import { DeleteData, SelectData, sortByField } from '../../../Database/db_helper'

import Navbar from '../Navbar'
import Btn from '../common/btn'
import { useAlert } from '../common/message'
import { dbCollections } from '../../../Database/tables'
import { eMsg } from '../common/enum'
import ModelCategory from './Models/ModelCategory'

export default function Categories() {
  const showMsg = useAlert();

  const [model, setModel] = useState('');
  const [CategoryData, setCategoryData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [ParentId, setParentId] = useState('0');
  const [Breadcrumb, setBreadcrumb] = useState([]);
  const [searchText, setSearchText] = useState('');

  //#region Header Buttons

  const AddCategory = () => {
    setModel(<ModelCategory category={null} onClose={onClose} MaxSrNo={GetMaxSrNo()} ParentId={ParentId} />);
  }

  const EditCategory = () => {
    const objCategory = GetSelectedItem(selectedRow, CategoryData);
    if (objCategory) {
      setModel(<ModelCategory category={objCategory} onClose={onClose} MaxSrNo={GetMaxSrNo()} ParentId={ParentId} />);
    }
    else {
      showMsg(eMsg.Info, 'No Category is selected.');
    }
  }

  const DeleteCategory = async () => {
    const objCategory = GetSelectedItem(selectedRow, CategoryData);
    if (objCategory) {
      await DeleteData(dbCollections.Category, objCategory.id);
      showMsg(eMsg.Success, 'Category Deleted Successfully.');
    }
    else {
      showMsg(eMsg.Info, 'No Category is selected.');
    }
  }

  const onClose = () => {
    setModel('');
    ResetData();
  }

  const GetMaxSrNo = () => {
    if (CategoryData.length > 0) {
      return CategoryData[CategoryData.length - 1].SerialNo;
    }
    else {
      return 0;
    }
  }

  //#endregion


  //#region  Load Data

  useEffect(() => { ResetData(); }, [ParentId]);

  const ResetData = async () => {
    const dtCategory = await SelectData(dbCollections.Category, { ParentId: ParentId });

    sortByField(dtCategory, 'SerialNo');
    setCategoryData(dtCategory);
  }

  const renderData = () => {
    return CategoryData.map((objCategory, Index) => (
      <tr key={Index} onClick={() => SelectRow(Index, selectedRow, setSelectedRow)} onDoubleClick={() => GetChildData(objCategory)} className={`bg-white border-b light:bg-gray-800 light:border-gray-700 hover:bg-blue-100 light:hover:bg-gray-600 ${selectedRow === Index ? 'grid-row-selected' : ''}`}>
        <td className="px-6 py-4"> {objCategory.SerialNo} </td>
        <td className="px-6 py-4"> {objCategory.Category} </td>
      </tr>
    ));
  }

  const GetChildData = (objCat) => {
    Breadcrumb.push(<a className='ml-3' onClick={() => RemoveBreadcrum(Breadcrumb.length - 1, objCat.id)}> &gt; &nbsp; {objCat.Category}</a>);
    setParentId(objCat.id);
    setBreadcrumb(Breadcrumb);
  }

  const RemoveBreadcrum = (Index, CurrentCatId) => {
    Breadcrumb.splice(Index, Breadcrumb.length - Index);
    setParentId(CurrentCatId);
  }

  //#endregion

  const breadcrumbElements = Breadcrumb.map((element, index) => (
    <React.Fragment key={index}>{element}</React.Fragment>
  ));

  return (
    <>
      <Navbar onSearch={setSearchText}>
        <a className='p-0' onClick={() => { RemoveBreadcrum(0, '0') }}>Categories</a>
        {breadcrumbElements}
      </Navbar>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className='flex'>
          <Btn onClick={AddCategory} Name='Add' Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500'>
            <i className='fa fa-plus mr-1'></i>
          </Btn>
          <Btn onClick={EditCategory} Name='Edit' Class='text-white bg-purple-700 hover:bg-purple-500 focus:ring-purple-200 light:bg-purple-300 light:hover:bg-purple-500 light:focus:ring-purple-500'>
            <i className='fa fa-edit mr-1'></i>
          </Btn>
          <Btn onClick={DeleteCategory} Name='Delete' Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500'>
            <i className='fa fa-ban mr-1'></i>
          </Btn>
        </div>
        <div className='overflow-auto' style={{ height: 'calc(100vh - 14.5rem)' }}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 light:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">SrNo</th>
                <th scope="col" className="px-6 py-3">Category</th>
              </tr>
            </thead>
            <tbody>
              {renderData()}
            </tbody>
          </table>
        </div>
      </div>
      {model}
    </>
  )
}
