import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';

import Navbar from '../Navbar'
import Btn from '../common/btn'
import { GetSelectedItem, SelectRow } from '../../../Database/helper';
import { DataFetch, DeleteData, sortByField } from '../../../Database/db_helper';
import { useAlert } from '../common/message';
import { eMsg } from '../common/enum';
import { dbCollections } from '../../../Database/tables';
import ModelPurity from './Models/ModelPurity';

export default function Purity() {
  const showMsg = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const [model, setModel] = useState('');
  const [PurityData, setPurityData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [State, setState] = useState(location.state);
  const [searchText, setSearchText] = useState('');

  //#region  Header Buttons

  const AddPurity = () => {
    setModel(<ModelPurity purity={null} onClose={onClose} MaxSrNo={GetMaxSrNo()} MaterialId={State.MaterialId} />);
  }

  const EditPurity = () => {
    const objPurity = GetSelectedItem(selectedRow, PurityData);
    if (objPurity) {
      setModel(<ModelPurity purity={objPurity} onClose={onClose} MaxSrNo={GetMaxSrNo()} MaterialId={State.MaterialId} />);
    }
    else {
      showMsg(eMsg.Info, 'No Data is selected.');
    }
  }

  const DeletePurity = async () => {
    const objPurity = GetSelectedItem(selectedRow, PurityData);
    if (objPurity) {
      await DeleteData(dbCollections.MaterialPurity, objPurity.id);
      showMsg(eMsg.Success, 'Data Deleted Successfully.');
    }
    else {
      showMsg(eMsg.Info, 'No Data is selected.');
    }
  }

  const onClose = () => {
    setModel('');
  }

  const GetMaxSrNo = () => {
    if (PurityData.length > 0) {
      return PurityData[PurityData.length - 1].SerialNo;
    }
    else {
      return 0;
    }
  }

  //#endregion

  //#region  Load Data

  useEffect(() => { DataFetch(dbCollections.MaterialPurity, { MaterialId: State.MaterialId }, ResetData) }, []);

  const ResetData = async (dtPurity) => {
    sortByField(dtPurity, 'SerialNo');
    setPurityData(dtPurity);
  }

  const renderData = () => {
    return PurityData.map((objPurity, Index) => (
      <tr key={Index} onClick={() => SelectRow(Index, selectedRow, setSelectedRow)} className={`bg-white border-b light:bg-gray-800 light:border-gray-700 hover:bg-blue-100 light:hover:bg-gray-600 ${selectedRow === Index ? 'grid-row-selected' : ''}`}>
        <td className="px-6 py-4 w-20"> {objPurity.SerialNo} </td>
        <td className="px-6 py-4"> {objPurity.Karat} K </td>
      </tr>
    ));
  }

  //#endregion

  return (
    <>
      <Navbar onClick={setSearchText}>
        <a className='p-0' onClick={() => { navigate('..') }}>Material</a>
        <a className='p-0 ml-2'> &gt; &nbsp; {State.Material}</a>
      </Navbar>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className='flex'>
          <Btn onClick={AddPurity} Name='Add' Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500'>
            <i className='fa fa-plus mr-1'></i>
          </Btn>
          <Btn onClick={EditPurity} Name='Edit' Class='text-white bg-purple-700 hover:bg-purple-500 focus:ring-purple-200 light:bg-purple-300 light:hover:bg-purple-500 light:focus:ring-purple-500'>
            <i className='fa fa-edit mr-1'></i>
          </Btn>
          <Btn onClick={DeletePurity} Name='Delete' Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500'>
            <i className='fa fa-ban mr-1'></i>
          </Btn>
        </div>
        <div className='overflow-auto' style={{ height: 'calc(100vh - 14.5rem)' }}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 light:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">SrNo</th>
                <th scope="col" className="px-6 py-3">Purity Index</th>
              </tr>
            </thead>
            <tbody>
              {renderData()}
            </tbody>
          </table>
        </div>
      </div>
      {model}
    </>
  )
}
