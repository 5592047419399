import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { GetSelectedItem, SelectRow } from '../../../Database/helper'
import { DataFetch, DeleteData, sortByField } from '../../../Database/db_helper'
import { dbCollections } from '../../../Database/tables'

import Btn from '../common/btn'
import { useAlert } from '../common/message'
import { eMsg } from '../common/enum'

import Navbar from '../Navbar'

import ModelMaterial from './Models/ModelMaterial'

export default function Material() {
    const showMsg = useAlert();
    const navigate = useNavigate();

    const [model, setModel] = useState('');
    const [MaterialData, setMaterialData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [searchText, setSearchText] = useState('');

    //#region Header Buttons

    const AddMaterial = () => {
        setModel(<ModelMaterial material={null} onClose={onClose} MaxSrNo={GetMaxSrNo()} />);
    }

    const EditMaterial = () => {
        const objMaterial = GetSelectedItem(selectedRow, MaterialData);
        if (objMaterial) {
            setModel(<ModelMaterial material={objMaterial} onClose={onClose} MaxSrNo={GetMaxSrNo()} />);
        }
        else {
            showMsg(eMsg.Info, 'No Material is selected.');
        }
    }

    const DeleteMaterial = async () => {
        const objMaterial = GetSelectedItem(selectedRow, MaterialData);
        if (objMaterial) {
            await DeleteData(dbCollections.Material, objMaterial.id);
            showMsg(eMsg.Success, 'Material Deleted Successfully.');
        }
        else {
            showMsg(eMsg.Info, 'No Material is selected.');
        }
    }

    const onClose = () => {
        setModel('');
    }

    const GetMaxSrNo = () => {
        if (MaterialData.length > 0) {
            return MaterialData[MaterialData.length - 1].SerialNo;
        }
        else {
            return 0;
        }
    }

    //#endregion


    //#region  Load Data

    useEffect(() => { DataFetch(dbCollections.Material, {}, ResetData) }, []);

    const ResetData = (dtMaterial) => {
        sortByField(dtMaterial, 'SerialNo');
        setMaterialData(dtMaterial);
    }

    const renderData = () => {
        return MaterialData.map((objMaterial, Index) => (
            <tr key={Index} onClick={() => SelectRow(Index, selectedRow, setSelectedRow)} onDoubleClick={() => GetPurity(objMaterial)} className={`bg-white border-b light:bg-gray-800 light:border-gray-700 hover:bg-blue-100 light:hover:bg-gray-600 ${selectedRow === Index ? 'grid-row-selected' : ''}`}>
                <td className="px-6 py-4 w-20"> {objMaterial.SerialNo} </td>
                <td className="px-6 py-4 flex">
                    <label className={`w-6 h-6 rounded-full p-1 d-block bg-[${objMaterial.Color}]`}></label>
                    <label className='my-auto ml-2'>{objMaterial.Material}</label>
                </td>
                <td className="px-6 py-4">$ {objMaterial.Price} </td>
            </tr>
        ));
    }

    const GetPurity = (Material) => {
        navigate('purity', { state: { MaterialId: Material.id, Material: Material.Material } });
    }

    //#endregion

    return (
        <>
            <Navbar Name='Material' onSearch={setSearchText} />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className='flex'>
                    <Btn onClick={AddMaterial} Name='Add' Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500'>
                        <i className='fa fa-plus mr-1'></i>
                    </Btn>
                    <Btn onClick={EditMaterial} Name='Edit' Class='text-white bg-purple-700 hover:bg-purple-500 focus:ring-purple-200 light:bg-purple-300 light:hover:bg-purple-500 light:focus:ring-purple-500'>
                        <i className='fa fa-edit mr-1'></i>
                    </Btn>
                    <Btn onClick={DeleteMaterial} Name='Delete' Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500'>
                        <i className='fa fa-ban mr-1'></i>
                    </Btn>
                </div>
                <div className='overflow-auto' style={{ height: 'calc(100vh - 14.5rem)' }}>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 light:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">SrNo</th>
                                <th scope="col" className="px-6 py-3">Material</th>
                                <th scope="col" className="px-6 py-3">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderData()}
                        </tbody>
                    </table>
                </div>
            </div>
            {model}
        </>
    )
}
