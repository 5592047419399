import React, { useEffect, useState } from 'react';

import '../../CSS/Pages/Home/Slider.css';
import { SelectData } from '../../../Database/db_helper';
import { dbCollections } from '../../../Database/tables';

export default function Slider() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [Images, setImages] = useState([]);

  const setData = async () => {
    const Slides = await SelectData(dbCollections.Slider, {});
    setImages(Slides);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [Images]);

  useEffect(() => { setData(); }, []);

  return (
    <div className="slider">
      {Images.map((item, index) => (
        <div key={index} className={`text-${item.TextColor.toLowerCase()}`}>
          <img src={item.ImageDownloadURL} alt="Image 1" loading='lazy' className={`${currentIndex === index ? 'active pop-in' : 'pop-out'} w-full h-auto`} />
        </div>
      ))}
    </div>
  )
}