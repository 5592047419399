import React from 'react';

import '../../CSS/Pages/Home/Blog.css';

import NecklessImg from '../../Images/image1.jpg';

export default function Blogs() {

  return (
    <div className='mt-12 md:mt-24'>
      <div className='c-shophistory'>
        <div className='c-shophistory-left col-lg-6 col-md-6 col-sm-12 col-xs-12' data-aos='fade-left'>
          <img className='c-shophistory-image mx-auto' loading="lazy" src={NecklessImg} alt='neckless' />
        </div>
        <div className='c-shophistory-details col-lg-6 col-md-6 col-sm-12 col-xs-12' data-aos='fade-right'>
          <div style={{ padding: '0px 50px' }}>
            <h1 className='c-shophistory-detail-header'>Shop History</h1>
            <p className='t-justify'>Village did removed enjoyed explain nor ham saw calling talking. Securing as informed declared or margaret. Joy horrible moreover man feelings own shy. Request norland neither mistake for yet. Between the for morning assured country believe.
              <br /><br />On even feet time have an no at. Relation so in confined smallest children unpacked delicate. Why sir end believe uncivil respect Always get adieus nature day course for common. My little garret repair to desire he esteem.
              <br /><br />Improve ashamed married expense bed her comfort pursuit rs.</p>
            <button className='Read-more hover:text-white hover:bg-black'>Read More</button>
          </div>
        </div>
      </div>
      <div className='w-full h-auto mt-12 md:mt-24' data-aos='fade-in'>
        <iframe className='w-full h-[50vw]' loading="lazy"
          src="https://player.vimeo.com/video/831531682?autoplay=1&autopause=1&background=1&loop=1&muted=1&transparent=0&responsive=1&portrait=0&title=0&byline=0&color=2e2e2e"
          // style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          allowFullScreen
          title="Embedded Vimeo Video"
        ></iframe>
      </div>
    </div>
  )
}
