import React, { useEffect, useState } from 'react';

import ProductDetails from './Product/Details';
import RelatedProducts from './Product/RelatedItems';
import { useLocation } from 'react-router';

export default function Product() {
  const location = useLocation();

  const [Product, setProduct] = useState();

  useEffect(() => {
    setProduct(location.state);
  }, [location.state]);

  return (
    <div className='pls-site-content m-10'>
      <div className='container'>
        <div className='row'>
          <div className='pls-content-area col-md-12'>
            <div className='pls-single-product-page pls-product-content-style-1 product-gallery-left pls-product-sticky product type-product post-208 status-publish first instock product_cat-earnings product_tag-modern product_tag-women has-post-thumbnail shipping-taxable purchasable product-type-simple'>
              {Product && (<>
                <ProductDetails key={Product.id} product={Product} />
                <RelatedProducts category={Product?.Category} />
              </>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}