import React, { useEffect, useState } from 'react';

import Sidebar from './Shop/Sidebar';
import Products from './Shop/Products';
import { useSearchParams } from 'react-router-dom';
import PageTitle from './Shop/PageTitle';

export default function Shop() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [SearchParams] = useSearchParams();

  const handleCategoryChange = (categoryId) => {
    const newSelectedCategories = [...selectedCategories]; // Create a copy to avoid mutation
    const categoryIndex = newSelectedCategories.indexOf(categoryId);

    if (categoryIndex !== -1) {
      // Category already selected, remove it
      newSelectedCategories.splice(categoryIndex, 1);
    } else {
      // Category not selected, add it
      newSelectedCategories.push(categoryId);
    }

    setSelectedCategories(newSelectedCategories);
  };

  useEffect(() => {
    const CategoryId = SearchParams.get('category');
    
    if (CategoryId) {
      setSelectedCategories([]);
      handleCategoryChange(CategoryId);
    }
  }, [SearchParams.get('category')]);

  return (
    <div>
      <PageTitle Name='Shop'/>
      <div style={{ margin: '30px', width: 'calc(100% - 30px)' }}>
        <div className='row sm:flex'>
          <Sidebar onCategoryChange={handleCategoryChange} selectedCategories={selectedCategories} />
          <Products selectedCategories={selectedCategories} />
        </div>
      </div>
    </div>
  )
}