import React from 'react';

import './CSS/Footer.css';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className='c-footer xs:d-block sm:flex'>
      <div className='c-footer-my_account'>
        <h4>MY ACCOUNT</h4>
        <ul className='ul-my_account'>
          <li><Link to='/accounts/dashboard'>My Accounts</Link></li>
          <li><Link to='/wishlist'>Wishlist</Link></li>
          <li><Link to='/accounts/cart'>Cart</Link></li>
          <li><Link to='/registration'>Register Now</Link></li>
        </ul>
      </div>
      <div className='c-footer-information'>
        <h4>INFORMATION</h4>
        <ul className='ul-information'>
          <li>About Us</li>
          <li>Our Blog</li>
          <li>Contacts</li>
          <li>FAQ</li>
          <li><Link to='privacy-policy'>Privacy Policy</Link></li>
          <li><Link to='shipping-delivery-policy'>Shipping & Delivery Policy</Link></li>
          <li><Link to='terms-conditions'>Terms & Conditions</Link></li>
          <li><Link to='refund-return-policy'>Refund and Return Policy</Link></li>
        </ul>
      </div>
      <div className='c-footer-contacts'>
        <h4>OUR CONTACTS</h4>
        <ul className='ul-our_contacts'>
          <li><i className='fa fa-map-marker cursor-pointer'></i><label className='ml-10px cursor-pointer'>283 N. Glenwood Street, Levittown, NY 11756</label></li>
          <li><a href='tel:712-233-9294'><i className='fa fa-phone cursor-pointer'></i><label className='ml-10px cursor-pointer'>712-233-9294</label></a></li>
          <li><i className='fa fa-clock-o cursor-pointer'></i><label className='ml-10px cursor-pointer'>Mon - Fri: 10:00 - 18:00</label></li>
          <li><a href='mailto:info@goldish-jew.com' target="_blank"><i className='fa fa-envelope-o cursor-pointer'></i><label className='ml-10px cursor-pointer'>info@goldish-jew.com</label></a></li>
          <li className='flex'>
            <label className='li-contact-icon cursor-pointer'><i className='fa fa-facebook'></i></label>
            <label className='li-contact-icon cursor-pointer ml-20px'><i className='fa fa-instagram'></i></label>
            <label className='li-contact-icon cursor-pointer ml-20px'><i className='fa fa-twitter'></i></label>
            <label className='li-contact-icon cursor-pointer ml-20px'><i className='fa fa-youtube-play'></i></label>
          </li>
        </ul>
      </div>
    </div>
  )
}
