import React, { useEffect, useState } from 'react'

import Dropdown from '../common/Dropdown'
import { DataFetch, InsertData, UpdateData } from '../../../Database/db_helper';
import { Configuration, dbCollections } from '../../../Database/tables';

export default function ConfigDisplaysite({ config }) {

    //#region Load Data

    const [lstProducts, setProducts] = useState([]);

    const [newArrival, setNewArrival] = useState();
    const [featured, setFeatured] = useState();
    const [onSale, setOnSale] = useState();

    const [lstNewArrival, setlstNewArrival] = useState([]);
    const [lstFeatured, setlstFeatured] = useState([]);
    const [lstSale, setlstSale] = useState([]);

    useEffect(() => {
        DataFetch(dbCollections.Product, {}, setProducts);
    }, []);

    useEffect(() => {
        if (config) {
            if (config.NewArrival) {
                const tmplstArrivals = lstProducts.filter(item => config.NewArrival.includes(item.id));
                setlstNewArrival(tmplstArrivals);
            }

            if (config.Featured) {
                const tmplstFeatured = lstProducts.filter(item => config.Featured.includes(item.id));
                setlstFeatured(tmplstFeatured);
            }

            if (config.OnSale) {
                const tmplstOnSale = lstProducts.filter(item => config.OnSale.includes(item.id));
                setlstSale(tmplstOnSale);
            }
        }
    }, [config]);

    //#endregion

    //#region Update Data

    const AddNewArrival = async () => {
        const Config = { ...Configuration };

        if (config.NewArrival) {
            let Arrival = config.NewArrival;
            Arrival.push(newArrival);

            Config.NewArrival = Arrival;
        }
        else {
            Config.NewArrival = [newArrival];
        }

        if (config && config.id) {
            await UpdateData(dbCollections.Configuration, config.id, Config);
        }
        else {
            await InsertData(dbCollections.Configuration, Config);
        }
    }

    const RemoveNewArrival = async (Id) => {
        const Config = { ...Configuration };

        if (config.NewArrival) {
            Config.NewArrival = config.NewArrival.filter(item => item !== Id);
            await UpdateData(dbCollections.Configuration, config.id, Config);
        }
    }

    const AddFeatured = async () => {
        const Config = { ...Configuration };

        if (config.Featured) {
            let Featured = config.Featured;
            Featured.push(featured);

            Config.Featured = Featured;
        }
        else {
            Config.Featured = [featured];
        }

        if (config && config.id) {
            await UpdateData(dbCollections.Configuration, config.id, Config);
        }
        else {
            await InsertData(dbCollections.Configuration, Config);
        }
    }

    const RemoveFeatured = async (Id) => {
        const Config = { ...Configuration };

        if (config.Featured) {
            Config.Featured = config.Featured.filter(item => item !== Id);
            await UpdateData(dbCollections.Configuration, config.id, Config);
        }
    }

    const AddOnSale = async () => {
        const Config = { ...Configuration };

        if (config.OnSale) {
            let OnSale = config.OnSale;
            OnSale.push(onSale);

            Config.OnSale = OnSale;
        }
        else {
            Config.OnSale = [onSale];
        }

        if (config && config.id) {
            await UpdateData(dbCollections.Configuration, config.id, Config);
        }
        else {
            await InsertData(dbCollections.Configuration, Config);
        }
    }

    const RemoveOnSale = async (Id) => {
        const Config = { ...Configuration };

        if (config.OnSale) {
            Config.OnSale = config.OnSale.filter(item => item !== Id);
            await UpdateData(dbCollections.Configuration, config.id, Config);
        }
    }

    //#endregion

    return (
        <>
            <div>
                <h3 className='text-md font-semibold'>New Arrived</h3>
                <div>
                    <table className='mt-10'>
                        <tbody>
                            {lstNewArrival.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4"><img src={item?.MaterialDetails[0]?.DiamondDetails[0]?.Images[0]?.Image[0]?.DownloadURL ?? ''} className='rounded-full w-12 h-12'></img></td>
                                    <td className="px-6 py-4 content-center">{item.ProductName}</td>
                                    <td className="px-6 py-4 content-center"><i className='fa fa-trash text-red-500' onClick={() => RemoveNewArrival(item.id)}></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='inline-flex mt-10'>
                        <Dropdown className='' Name='Product' AddItem='True' items={lstProducts.map(product => [product.ProductName, product.id])} Value={newArrival} onSelectChange={setNewArrival} />
                        <button className='border border-gray-300 hover:border-gray-400 px-4 rounded-md ml-3' onClick={() => AddNewArrival()}>Add</button>
                    </div>
                </div>
            </div>
            <div className='mt-4 border-t border-gray-300 pt-4'>
                <h3 className='text-md font-semibold'>Featured</h3>
                <div>
                    <table className='mt-10'>
                        <tbody>
                            {lstFeatured.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4"><img src={item?.MaterialDetails[0]?.DiamondDetails[0]?.Images[0]?.Image[0]?.DownloadURL ?? ''} className='rounded-full w-12 h-12'></img></td>
                                    <td className="px-6 py-4 content-center">{item.ProductName}</td>
                                    <td className="px-6 py-4 content-center"><i className='fa fa-trash text-red-500' onClick={() => RemoveFeatured(item.id)}></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='inline-flex mt-10'>
                        <Dropdown className='' Name='Product' AddItem='True' items={lstProducts.map(product => [product.ProductName, product.id])} Value={featured} onSelectChange={setFeatured} />
                        <button className='border border-gray-300 hover:border-gray-400 px-4 rounded-md ml-3' onClick={() => AddFeatured()}>Add</button>
                    </div>
                </div>
            </div>
            <div className='mt-4 border-t border-gray-300 pt-4'>
                <h3 className='text-md font-semibold'>On Sale</h3>
                <div>
                    <table className='mt-10'>
                        <tbody>
                            {lstSale.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4"><img src={item?.MaterialDetails[0]?.DiamondDetails[0]?.Images[0]?.Image[0]?.DownloadURL ?? ''} className='rounded-full w-12 h-12'></img></td>
                                    <td className="px-6 py-4 content-center">{item.ProductName}</td>
                                    <td className="px-6 py-4 content-center"><i className='fa fa-trash text-red-500' onClick={() => RemoveOnSale(item.id)}></i></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='inline-flex mt-10'>
                        <Dropdown className='' Name='Product' AddItem='True' items={lstProducts.map(product => [product.ProductName, product.id])} Value={onSale} onSelectChange={setOnSale} />
                        <button className='border border-gray-300 hover:border-gray-400 px-4 rounded-md ml-3' onClick={() => AddOnSale()}>Add</button>
                    </div>
                </div>
            </div>
        </>
    )
}
