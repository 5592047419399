import React, { useEffect, useState } from 'react'

import { Product, dbCollections } from '../../../../Database/tables'

import Btn from '../../common/btn'
import { GetTime, InsertData, SelectData, UpdateData, sortByField } from '../../../../Database/db_helper'
import { useAlert } from '../../common/message'
import { IsEmpty, IsSelected } from '../../../../Database/validation'
import { eMsg } from '../../common/enum'
import Dropdown from '../../common/Dropdown'

export default function ModelProduct({ product, onClose }) {
    const showMsg = useAlert();

    const [objProduct, setProduct] = useState({ ...Product });
    const [lstCategory, setCategory] = useState([]);

    const IsValid = async () => {
        if (IsEmpty(objProduct.ProductName)) {
            showMsg(eMsg.Info, 'Please Enter Product Name.');
            return false;
        }

        for (const size of objProduct.MaterialDetails) {
            if (!IsSelected(size.Size)) {
                showMsg(eMsg.Info, 'Select Size.');
                return false;
            }

            if (IsEmpty(size.MaterialWeight)) {
                showMsg(eMsg.Info, 'Enter Material Weight.');
                return false;
            }
        }

        if (IsEmpty(objProduct.Category)) {
            showMsg(eMsg.Info, 'Please Select Category.');
            return false;
        }

        return true;
    }

    const SaveData = async () => {
        if (await IsValid()) {
            if (product) {
                await UpdateData(dbCollections.Product, product.id, objProduct);
                showMsg(eMsg.Success, 'Product Updated Successfully.');
            }
            else {
                objProduct.InsertTime = await GetTime();
                await InsertData(dbCollections.Product, objProduct);
                showMsg(eMsg.Success, 'Product Inserted Successfully.');
            }

            onClose();
        }
        else{
            
        }
    }

    const AddSize = () => {
        const newProduct = { ...objProduct }; // Create a copy of objProduct
        newProduct.MaterialDetails.push({
            Size: null,
            MaterialWeight: null,
            DiamondDetails: [
                {
                    Carat: null,
                    DiamondId: null,
                    Diamonds: [
                        {
                            DiamondId: null,
                            DiamondSize: null,
                            DiamondCount: null,
                        }
                    ],
                    Images: [
                        {
                            MaterialId: null,
                            Material: null,
                            Image: [
                                {
                                    ImageURL: null,
                                    DownloadURL: null
                                }
                            ]
                        }
                    ]
                }
            ]
        });

        setProduct(newProduct); // Update state with the new object
    }

    const DeleteSize = (Index) => {
        const newProduct = { ...objProduct }; // Create a copy of objProduct
        newProduct.MaterialDetails.splice(Index, 1);
        setProduct(newProduct); // Update state with the new object
    }

    const setData = async () => {
        let dtCategory = await SelectData(dbCollections.Category, {});
        sortByField(dtCategory, 'SerialNo');
        sortByField(dtCategory, 'ParentId');
        dtCategory = dtCategory.map(category => [category.Category, category.id]);
        setCategory(dtCategory);

        if (product) {
            setProduct(product);
        }
        else {
            AddSize();
            DeleteSize(0);
        }
    }

    useEffect(() => {
        setData();
    }, []);

    //#region  Data onChange

    const updateName = (Name) => {
        const tmpobjProduct = { ...objProduct };
        tmpobjProduct.ProductName = Name;
        setProduct(tmpobjProduct);
    }

    const updateCategory = (Id) => {
        const tmpobjProduct = { ...objProduct };
        tmpobjProduct.Category = Id;
        setProduct(tmpobjProduct);
    }

    const updateSize = (index, Size) => {
        const tmpobjProduct = { ...objProduct };
        tmpobjProduct.MaterialDetails[index].Size = Size;
        setProduct(tmpobjProduct);
    }

    const updateWeight = (index, Weight) => {
        const tmpobjProduct = { ...objProduct };
        tmpobjProduct.MaterialDetails[index].MaterialWeight = Weight ? parseFloat(Weight) : 0;
        setProduct(tmpobjProduct);
    }

    const updateDescription = (Desc) => {
        const tmpobjProduct = { ...objProduct };
        tmpobjProduct.Description = Desc;
        setProduct(tmpobjProduct);
    }

    //#endregion

    return (
        <div tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 max-h-full" style={{ backgroundColor: 'rgba(135, 135, 135, 0.50)' }}>
            <div className="relative p-4 w-full max-w-xl max-h-full mx-auto my-auto">
                <div className="relative bg-white rounded-lg shadow light:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t light:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                            {product ? 'Edit' : 'Add New'} Product
                        </h3>
                        <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5 text-left">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Product Name</label>
                                <input type="text" value={objProduct.ProductName} onChange={(e) => updateName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Type Material Name..." />
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Category</label>
                                <Dropdown className='w-full' Name='Category' items={lstCategory} onSelectChange={(Id) => updateCategory(Id)} Value={objProduct.Category} AddItem={true}></Dropdown>
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Material Weight</label>
                                {objProduct.MaterialDetails && objProduct.MaterialDetails.map((size, Index) => (
                                    <div className='mt-5 flex'>
                                        <label className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>Size{Index + 1} : </label>
                                        <input type='text' value={size.Size} onChange={(e) => updateSize(Index, e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block h-10 p-2.5 col-span-1 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Enter Size..." ></input>
                                        <input type="number" value={size.MaterialWeight} onChange={(e) => updateWeight(Index, e.target.value)} className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block h-10 p-2.5 col-span-1 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Enter Material Weight..." />
                                        <Btn Class='text-red-500 text-lg p-0 ml-2 mb-0' onClick={() => DeleteSize(Index)}><i className='fa fa-trash'></i></Btn>
                                    </div>
                                ))}
                                <Btn Class='float-left mt-2 hover:bg-black hover:text-white' onClick={AddSize} Name='Add Size'></Btn>
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Description</label>
                                <textarea id="description" rows="4" value={objProduct.Description} onChange={(e) => updateDescription(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-blue-500 light:focus:border-blue-500" placeholder="Write Product Description here..."></textarea>
                            </div>
                        </div>
                        <Btn Name='Save' onClick={SaveData} Class='text-white bg-blue-700 hover:bg-blue-800 light:bg-blue-600 light:hover:bg-blue-700 light:focus:ring-blue-800' />
                        <Btn Name='Cancel' onClick={onClose} Class='text-white bg-gray-500 hover:bg-gray-600 light:bg-gray-400 light:hover:bg-gray-500 light:focus:ring-gray-600' />
                    </div>
                </div>
            </div>
        </div>
    )
}
