import React from 'react'
import Navbar from './Navbar'

export default function Dashboard() {
    return (
        <>
            <Navbar Name='Dashboard' />
        </>
    )
}
