import React, { useState } from 'react';

export default function ContactUs() {
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Message, setMessage] = useState('');

    return (
        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact sales</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600"> Elevate your style with our exquisite jewelry collection today! </p>
            </div>
            <div className="mx-auto mt-16 max-w-xl sm:mt-20 text-left">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlhtmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900"> First name </label>
                        <div className="mt-2.5">
                            <input type="text" placeholder='First Name' value={FirstName} onChange={(e) => setFirstName(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label htmlhtmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900"> Last name </label>
                        <div className="mt-2.5">
                            <input type="text" placeholder='Last Name' value={LastName} onChange={(e) => setLastName(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlhtmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900"> Email </label>
                        <div className="mt-2.5">
                            <input type="email" placeholder='Enter Your Email' value={Email} onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlhtmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900"> Phone number </label>
                        <div className="relative mt-2.5">
                            <input type="tel" placeholder='Enter Your Phone Number' value={Phone} onChange={(e) => setPhone(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlhtmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                            Message
                        </label>
                        <div className="mt-2.5">
                            <textarea value={Message} onChange={(e) => setMessage(e.target.value)} rows={4} placeholder='Enter Your Message Here...'
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <button type="submit" className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" > Let's talk </button>
                </div>
            </div>
        </div>
    )
}
