import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import Btn from '../../../../admin/components/common/btn';
import { useNotify } from '../../../Components/Notify';
import { dbCollections } from '../../../../Database/tables';
import { GetTime, InsertData } from '../../../../Database/db_helper';
import { IsEmpty, IsPhone } from '../../../../Database/validation';
import { useData } from '../../../Data';

import logo from '../../../Images/logo/logo.png';

export default function PopupCheckout({ order, onClose, onSuccess }) {

    const Notify = useNotify();
    const { lstCustomer } = useData();

    // const [IsPaymentMode, setIsPaymentMode] = useState(false);

    const [lstCities, setCities] = useState([]);

    const [Address1, setAddress1] = useState();
    const [Address2, setAddress2] = useState();
    const [City, setCity] = useState();
    const [State, setState] = useState();
    const [Zip, setZip] = useState();
    const [Country, setCountry] = useState();
    const [Phone, setPhone] = useState();

    const [errorAddress, setErrorAddress] = useState();
    const [errorCity, setErrorCity] = useState();
    const [errorState, setErrorState] = useState();
    const [errorZip, setErrorZip] = useState();
    const [errorCountry, setErrorCountry] = useState();
    const [errorPhone, setErrorPhone] = useState();

    const IsValid = async () => {

        if (IsEmpty(Address1)) {
            setErrorAddress("please enter Address1.");
            return false;
        }
        else {
            setErrorAddress('');
        }

        if (IsEmpty(City)) {
            setErrorCity("please enter City.");
            return false;
        }
        else {
            setErrorCity('');
        }

        if (IsEmpty(State)) {
            setErrorState("please enter State.");
            return false;
        }
        else {
            setErrorState('');
        }

        if (IsEmpty(Zip)) {
            setErrorZip("please enter Zip.");
            return false;
        }
        else {
            setErrorZip('');
        }

        if (IsEmpty(Country)) {
            setErrorCountry("please enter Country.");
            return false;
        }
        else {
            setErrorCountry('');
        }

        if (IsEmpty(Phone)) {
            setErrorPhone("please enter Phone number.");
            return false;
        }
        else if (!IsPhone(Phone)) {
            setErrorPhone("please enter valid Phone number.");
            return false;
        }
        // else if (!await checkPhoneNumberStatus(Phone)) {
        //     setErrorPhone("this Phone number does not exist.");
        //     return false;
        // }
        else {
            setErrorPhone('');
        }

        return true;
    }

    // async function checkPhoneNumberStatus(phoneNumber) {
    //     console.log('enter...');
    //     // const regex = /^[0-9]{3}[0-9]{3}[0-9]{4}$/;
    //     // if (regex.test(phoneNumber))
    //     //     return false;

    //     console.log('enter2...');
    //     const apiUrl = `https://telecom-provider-api.com/check_number?phone=${phoneNumber}`;

    //     try {
    //         const response = await fetch(apiUrl);
    //         console.log(response);
    //         if (!response.ok) {
    //             setErrorPhone('this phone number does not exist.');
    //             return false;
    //         }

    //         return true;
    //     } catch (error) {
    //         console.error('Error checking phone number status:', error);
    //         return false;
    //     }
    // }

    const onCountinue = async () => {
        if (!(await IsValid())) {
            return;
        }

        const objOrder = { ...order };
        objOrder.shipping_address.Address1 = Address1;
        objOrder.shipping_address.Address2 = Address2;
        objOrder.shipping_address.City = City;
        objOrder.shipping_address.State = State;
        objOrder.shipping_address.Zip = Zip;
        objOrder.shipping_address.Country = Country;

        makePayment(objOrder);

        onClose();
    }

    const loadCities = async (state) => {
        try {
          const response = await fetch(`https://countriesnow.space/api/v0.1/countries/state/cities/q?country=United%20States&state=${state}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          });
    
          if (!response.ok)
            setCities([]);
          else
          setCities((await response.json()).data);
    
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      }

      useEffect(() => {
        loadCities(State);
      }, [State]);
    
    const SelectHomeAdd = () => {
        const objCustom = lstCustomer.find(x => x.id === Cookies.get('CustomerId'));

        if (objCustom) {
            setAddress1(objCustom.home_address1);
            setAddress2(objCustom.home_address2);
            setZip(objCustom.home_zip);
            setCity(objCustom.home_city);
            setState(objCustom.home_state);
            setCountry(objCustom.home_country);
            setPhone(objCustom.phone_number);
        }
    }

    const SelectOfficeAdd = () => {
        const objCustom = lstCustomer.find(x => x.id === Cookies.get('CustomerId'));

        if (objCustom) {
            setAddress1(objCustom.office_address1);
            setAddress2(objCustom.office_address2);
            setZip(objCustom.office_zip);
            setCity(objCustom.office_city);
            setState(objCustom.office_state);
            setCountry(objCustom.office_country);
            setPhone(objCustom.phone_number);
        }
    }

    const makePayment = async (Order) => {
        try {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                // Initialize Razorpay
                const options = {
                    key: 'rzp_test_1DP5mmOlF5G5ag', // Replace with your Razorpay key ID
                    amount: (Order.payment.Amount * 100), // Amount in paise (50000 paise = INR 500)
                    currency: 'USD',
                    name: 'All Jewelz',
                    description: '',
                    image: logo, // Replace with your logo URL
                    handler: async function (response) {
                        if (response) {
                            Order.payment.razorpay_payment_id = response.razorpay_payment_id;
                            Order.payment.Status = 'Paid';
                            Order.payment.Currency = 'USD';
                            Order.last_updatetime = await GetTime();

                            await InsertData(dbCollections.Order, Order);

                            Notify('Success', 'Your Order Placed Successfully.\n Thank You!');
                            onSuccess();
                            // alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                        }
                        // You can handle the response here, e.g., save it to your database
                    },
                    // prefill: {
                    //     name: 'Your Customer Name',
                    //     email: 'customer@example.com',
                    //     contact: '9999999999',
                    // },
                    // notes: {
                    //     address: 'Customer Address',
                    // },
                    theme: {
                        color: '#EC4899',
                    },
                };

                const rzp = new window.Razorpay(options);
                rzp.open();
            };
            script.onerror = () => {
                alert('Razorpay SDK failed to load. Are you online?');
            };
            document.body.appendChild(script);

        } catch (error) {
            Notify('error', error);
        }
    }

    return (
        <div aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 max-h-full" style={{ backgroundColor: 'rgba(135, 135, 135, 0.50)' }}>
            <div className="relative p-4 w-full max-w-2xl max-h-full mx-auto my-auto">
                <div className="relative bg-white rounded-lg shadow light:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t light:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 text-center w-full light:text-white">
                            Shipping Information
                        </h3>
                        <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className='my-3 mx-5 w-full text-left'>
                        <input type='checkbox' name='chkHomeAdd' id="chkHomeAdd" className='mr-1' onChange={() => SelectHomeAdd()}></input><label for="chkHomeAdd">Home Address</label>
                        <input type='checkbox' name='chkOfficeAdd' id='chkOfficeAdd' className='ml-5 mr-1' onChange={() => SelectOfficeAdd()}></input><label for="chkOfficeAdd">Office Address</label>
                    </div>
                    <div className="p-4 md:p-5 text-left">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="shipping-address1">
                                    Address1  <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <textarea value={Address1} onChange={(e) => setAddress1(e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500" id="shipping-address1" placeholder="Home Address" required />
                                <p className="text-red-400 text-xs italic">{errorAddress}</p>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="shipping-address2">
                                    Address2  <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <textarea value={Address2} onChange={(e) => setAddress2(e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500" id="shipping-address2" placeholder="Street Address" />
                            </div>
                        </div>
                        <div className="md:flex md:flex-wrap -mx-3 mb-6">
                            <div className="w-full md:!w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                                    City  <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <div className='relative'>
                                    <select value={City} onChange={(e) => setCity(e.target.value)} className="block appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500" id="grid-state">
                                        <option value='0'>-- Please Select City</option>
                                        {lstCities.map((city, index) => (<option key={index} value={city}>{city}</option>))}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                                {/* <input value={City} onChange={(e) => setCity(e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-city" type="text" placeholder="Albuquerque" required /> */}
                                <p className="text-red-500 text-xs italic">{errorCity}</p>
                            </div>
                            <div className="w-full md:!w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                                    State  <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <div className="relative">
                                    <select value={State} onChange={(e) => setState(e.target.value)} className="block appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500" id="grid-state">
                                        <option value='0'>-- Please Select State --</option>
                                        <option value='Alabama'>Alabama</option>
                                        <option value='Alaska'>Alaska</option>
                                        <option value='Arizona'>Arizona</option>
                                        <option value='Arkansas'>Arkansas</option>
                                        <option value='California'>California</option>
                                        <option value='Colorado'>Colorado</option>
                                        <option value='Connecticut'>Connecticut</option>
                                        <option value='Delaware'>Delaware</option>
                                        <option value='Florida'>Florida</option>
                                        <option value='Georgia'>Georgia</option>
                                        <option value='Hawaii'>Hawaii</option>
                                        <option value='Idaho'>Idaho</option>
                                        <option value='Illinois'>Illinois</option>
                                        <option value='Indiana'>Indiana</option>
                                        <option value='Iowa'>Iowa</option>
                                        <option value='Kansas'>Kansas</option>
                                        <option value='Kentucky'>Kentucky</option>
                                        <option value='Louisiana'>Louisiana</option>
                                        <option value='Maine'>Maine</option>
                                        <option value='Maryland'>Maryland</option>
                                        <option value='Massachusetts'>Massachusetts</option>
                                        <option value='Michigan'>Michigan</option>
                                        <option value='Minnesota'>Minnesota</option>
                                        <option value='Mississippi'>Mississippi</option>
                                        <option value='Missouri'>Missouri</option>
                                        <option value='Montana'>Montana</option>
                                        <option value='Nebraska'>Nebraska</option>
                                        <option value='Nevada'>Nevada</option>
                                        <option value='New Hampshire'>New Hampshire</option>
                                        <option value='New Jersey'>New Jersey</option>
                                        <option value='New Mexico'>New Mexico</option>
                                        <option value='New York'>New York</option>
                                        <option value='North Carolina'>North Carolina</option>
                                        <option value='North Dakota'>North Dakota</option>
                                        <option value='Ohio'>Ohio</option>
                                        <option value='Oklahoma'>Oklahoma</option>
                                        <option value='Oregon'>Oregon</option>
                                        <option value='Pennsylvania'>Pennsylvania</option>
                                        <option value='Rhode Island'>Rhode Island</option>
                                        <option value='South Carolina'>South Carolina</option>
                                        <option value='South Dakota'>South Dakota</option>
                                        <option value='Tennessee'>Tennessee</option>
                                        <option value='Texas'>Texas</option>
                                        <option value='Utah'>Utah</option>
                                        <option value='Vermont'>Vermont</option>
                                        <option value='Virginia'>Virginia</option>
                                        <option value='Washington'>Washington</option>
                                        <option value='West Virginia'>West Virginia</option>
                                        <option value='Wisconsin'>Wisconsin</option>
                                        <option value='Wyoming'>Wyoming</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                                <p className="text-red-500 text-xs italic">{errorState}</p>
                            </div>
                        </div>
                        <div className="md:flex md:flex-wrap -mx-3 mb-6">
                            <div className="w-full md:!w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                                    Zip <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <input value={Zip} onChange={(e) => setZip(e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-zip" type="text" placeholder="90210" />
                                <p className="text-red-500 text-xs italic">{errorZip}</p>
                            </div>
                            <div className="w-full md:!w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                                    Country  <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <div className="relative">
                                    <select value={Country} onChange={(e) => setCountry(e.target.value)} className="block appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500" id="grid-state">
                                        <option value='0'>-- Please Select Country --</option>
                                        <option value='United States'>United States</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                    </div>
                                </div>
                                <p className="text-red-500 text-xs italic">{errorCountry}</p>
                            </div>
                        </div>
                        <div className="md:flex md:flex-wrap -mx-3 mb-6">
                            <div className="w-full md:!w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                                    Phone  <span className='text-red-400 text-lg'>*</span>
                                </label>
                                <div className="relative flex">
                                    <div className='w-1/3 border border-gray-300 focus:border-gray-500 flex'>
                                        <select className="block appearance-none w-full text-gray-700 py-3 pl-4 rounded leading-tight focus:outline-none text-sm" id="grid-state">
                                            <option value='+91'>In (+1)</option>
                                        </select>
                                        <div className="pointer-events-none inset-y-0 flex items-center px-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                    </div>
                                    <div className='w-2/3'>
                                        <input value={Phone} onChange={(e) => setPhone(e.target.value)} className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-zip" type="text" placeholder="90210" />
                                    </div>
                                </div>
                                <p className="text-red-500 text-xs italic">{errorPhone}</p>
                            </div>
                            <div className="w-full md:!w-1/2 px-3">

                            </div>
                        </div>
                        <Btn Name='Save & Continue' onClick={onCountinue} Class='text-white bg-blue-700 hover:bg-blue-800 light:bg-blue-600 light:hover:bg-blue-700 light:focus:ring-blue-800' />
                        <Btn Name='Cancel' onClick={onClose} Class='text-white bg-gray-500 hover:bg-gray-600 light:bg-gray-400 light:hover:bg-gray-500 light:focus:ring-gray-600' />
                    </div>
                </div>
            </div>
        </div>
    )
}
