const IsNumber = (str) => {
    return !isNaN(str);
}

const IsEmpty = (str) => {
    if (str && (IsNumber(str) || str.trim()))
        return false;
    else
        return true;
}

const IsNegativeNum = (str) => {
    return 0 > parseInt(str);
}

const IsSelected = (str) => {
    if (IsEmpty(str) || str === '0')
        return false;
    else
        return true;
}

const IsEmail = (str) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(str);
}

const IsPhone = (str) => {
    // const phoneRegex = /^\d{10}$/; // Assumes phone number is exactly 10 digits
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(str);
}

const timestampTOstring = (time) => {
    // let dateObject = time.toDate();

    // let year = dateObject.getFullYear();
    // let month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 to month because JavaScript months are zero-based
    // let day = ('0' + dateObject.getDate()).slice(-2);
    // let hours = ('0' + dateObject.getHours()).slice(-2);
    // let minutes = ('0' + dateObject.getMinutes()).slice(-2);

    // // Construct the formatted string
    // let formattedString = `${year}-${month}-${day}T${hours}:${minutes}`;

    // return formattedString;

    const date = time.toDate();

    // Get the month name
    const options = { month: 'long' };
    const month = new Intl.DateTimeFormat('en-US', options).format(date);

    // Get the day, year, and time
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Return the formatted date string
    return `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
}

// const formatDate = (time) => {
//     const date = time.toDate();

//     // Get the month name
//     const options = { month: 'long' };
//     const month = new Intl.DateTimeFormat('en-US', options).format(date);

//     // Get the day, year, and time
//     const day = date.getDate();
//     const year = date.getFullYear();
//     let hours = date.getHours();
//     const minutes = date.getMinutes().toString().padStart(2, '0');

//     // Determine AM or PM
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12 || 12; // Convert to 12-hour format

//     // Return the formatted date string
//     return `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
// };

export { IsEmpty, IsNumber, IsEmail, IsPhone, IsNegativeNum, IsSelected, timestampTOstring }