import React, { useEffect, useState } from 'react'
import Btn from '../common/btn'
import Navbar from '../Navbar'
import Accordian from '../common/Accordian'
import { DataFetch, GetTime, sortDescByField, UpdateData } from '../../../Database/db_helper';
import { dbCollections, Order } from '../../../Database/tables';
import { GetSelectedItem, SelectRow } from '../../../Database/helper';
import { eOrderStatus, GetEnumName } from '../common/enum';
import { useNotify } from '../../../website/Components/Notify';

export default function Orders() {
    const notify = useNotify();

    const [lstOrders, setlstOrders] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        DataFetch(dbCollections.Order, {}, ResetData);
    }, []);

    const ResetData = (data) => {
        sortDescByField(data, 'order_time');
        setlstOrders(data);
    }

    const UpdateStatus = async () => {
        const { id, ...objOrder } = GetSelectedItem(selectedRow, lstOrders);

        if (objOrder.order_status < eOrderStatus.Complete) {
            objOrder.order_status += 1;
            objOrder.last_updatetime = await GetTime();

            if (await UpdateData(dbCollections.Order, id, objOrder)) {
                notify('success', `Order ${GetEnumName(eOrderStatus, objOrder.order_status)} Successfully.`);
            }
            else {
                notify('error', 'Order Update Fail.');
            }
        }
        else{
            notify('error', 'You can not Update Order.');
        }
    }

    const CancelOrder = async () => {
        const { id, ...objOrder } = GetSelectedItem(selectedRow, lstOrders);

        if (objOrder.order_status < eOrderStatus.Complete) {
            objOrder.order_status = eOrderStatus.Cancel;
            objOrder.last_updatetime = await GetTime();

            if (await UpdateData(dbCollections.Order, id, objOrder)) {
                notify('success', 'Order Cancel Successfully.');
            }
            else {
                notify('error', 'Order Cancel Fail.');
            }
        }
        else{
            notify('error', 'You can not Cancel Order.');
        }
    }

    return (
        <>
            <Navbar Name='Orders' onSearch={setSearchText} />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className='flex'>
                    <Btn Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500' Name='Change Status' onClick={() => UpdateStatus()}>
                        <i className='fa fa-check mr-1'></i>
                    </Btn>
                    <Btn Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500' Name='Cancel' onClick={() => CancelOrder()}>
                        <i className='fa fa-ban mr-1'></i>
                    </Btn>
                </div>
                <div className='overflow-auto text-left' style={{ height: 'calc(100vh - 14.5rem)' }}>
                    <div className={`d-flex`}>
                        <label className='p-3 w-20 text-center'>Sr No</label>
                        <label className='p-3 w-96'>Customer</label>
                        <label className='p-3 w-28'>Amount</label>
                        <label className='p-3 w-36 text-center'>Status</label>
                        <label className='p-3 w-64'>Order Time</label>
                    </div>
                    {lstOrders.map((Order, Index) =>
                        (searchText === '' || Order.customer?.name.toLowerCase().includes(searchText.toLowerCase())) &&
                        (<Accordian order={Order} Index={Index} SelectedIndex={selectedRow} onclick={(index) => SelectRow(index, selectedRow, setSelectedRow)} />))}
                </div>
            </div>
        </>
    )
}
