import React, { useEffect, useState } from 'react';
import './CSS/Header.css';
import Cookies from 'js-cookie';
import { useData } from './Data';
import { Link, useNavigate } from 'react-router-dom';

import logo from './Images/logo/logo.png';

export default function Header() {
    const { lstCategory, lstWishlist, lstCartItems } = useData();
    const navigate = useNavigate();

    const [UserName, setUserName] = useState('');

    useEffect(() => {
        let name = Cookies.get('CustomerName');
        if (name) {
            name = name.replace(/%20/g, ' ');
            setUserName(name);
        }
    }, []);

    const logout = () => {
        try { Cookies.remove('CustomerId'); } catch { }
        try { Cookies.remove('CustomerName'); } catch { }
        setUserName('');
        navigate('/');
    };

    const GetSubCategories = (Categories) => {
        return Categories.map((Category) => {
            const subCategory = lstCategory.filter(cate => cate.ParentId === Category.id);
            return (<li key={Category.id} className='px-4 py-2'>
                {
                    subCategory.length > 0 ?
                        (<>
                            <div>
                                <label className='hover:text-yellow-600' onClick={() => GoToShop(Category.id)}>{Category.Category}</label>
                                <span className='fa fa-angle-right' onClick={(e) => toggleSubmenu(e)}></span>
                            </div>
                            <ul className='text-left text-md c-top-subsubmenu shadow h-0 h-fit hidden' onMouseLeave={(e) => { e.target.classList.toggle('hidden') }}>
                                {GetSubCategories(subCategory)}
                            </ul>
                        </>)
                        :
                        <label className='hover:text-yellow-600' onClick={() => GoToShop(Category.id)}>{Category.Category}</label>
                }
            </li>)
        });
    }

    const renderMenus = () => {
        const MainCategories = lstCategory.filter(cate => cate.ParentId === '0');

        return MainCategories.map((Category) => {
            const subCategory = lstCategory.filter(cate => cate.ParentId === Category.id);
            return (<li key={Category.id} className='ml-20px'>
                {
                    subCategory.length > 0 ?
                        (<>
                            <div>
                                <label className='cursor-pointer hover:text-yellow-600' onClick={() => GoToShop(Category.id)}>{Category.Category}</label>
                                <span className='fa fa-angle-down' onClick={(e) => toggleSubmenu(e)}></span>
                            </div>
                            <ul className={`text-left text-md c-top-submenu shadow hidden`} onMouseLeave={(e) => { e.target.classList.toggle('hidden') }}>
                                {GetSubCategories(subCategory)}
                            </ul>
                        </>)
                        :
                        <label className='cursor-pointer hover:text-yellow-600' onClick={() => GoToShop(Category.id)}>{Category.Category}</label>
                }
            </li>)
        });
    }

    const GoToShop = (CategoryId) => {
        navigate(`/shop?category=${CategoryId}`);
    }

    // Function to toggle the submenu visibility
    const toggleSubmenu = (e) => {
        const targetUl = e.target.parentElement.nextElementSibling;
        targetUl.classList.toggle('hidden');
    }

    const toggleSidebar = () => {
        const sidebar = document.getElementById('c-top-submenu');
        sidebar.classList.toggle('hidden');
    }

    return (
        <div className='c-header' data-aos='fade-down' style={{ fontFamily: 'Madera, sans-serif' }}>
            <div className='c-header_row-top sm:flex w-full p-2 sm:p-4'>
                <div className='c-header_cell-top_left flex my-auto xl:w-1/3 w-full relative'>
                    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" onClick={() => toggleSidebar()}
                        className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden mr-3 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 light:text-gray-400 light:hover:bg-gray-700 light:focus:ring-gray-600">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-8 h-8 my-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd"
                                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
                            </path>
                        </svg>
                    </button>
                    <ul id='c-top-submenu' className={`text-left top-14 c-top-submenu hidden`} onMouseLeave={(e) => { e.target.classList.toggle('hidden') }}>
                        <li className='px-4 py-2 hover:text-yellow-600'><Link to='/'>Home</Link></li>
                        <li className='px-4 py-2 hover:text-yellow-600'><Link to='/shop'>Shop</Link></li>
                        {lstCategory && GetSubCategories(lstCategory.filter(cate => cate.ParentId === '0'))}
                    </ul>
                    <img src={logo} className='w-44' alt='ALL JEWELZ'></img>
                </div>
                <div className='my-auto md:w-1/3 md:min-w-fit overflow-auto hidden lg:block'>
                    <div className='c-header_cell-bottom_center'>
                        <ul className='w-max d-flex mx-auto overflow-auto uppercase text-gray-600 py-2 text-xs sm:text-sm md:text-md'>
                            <li className='hover:text-yellow-600'><Link to='/'>Home</Link></li>
                            <li className='ml-20px hover:text-yellow-600'><Link to='/shop'>Shop</Link></li>
                            {renderMenus()}
                        </ul>
                    </div>
                </div>
                <div className='text-right my-auto w-full lg:w-1/3'>
                    <div className='ml-auto w-min inline-flex'>
                        <Link to='/wishlist' className='relative ml-20px' title='wishlist'>
                            <svg className="w-7 h-7 text-pink-500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 26 26">
                                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41 1.01 4.5 2.09C13.09 4.01 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                            </svg>
                            <span className="absolute top-1 left-2.5 text-white text-xs font-bold">
                                {lstWishlist ? lstWishlist.length : 0}
                            </span>
                        </Link>
                        <Link to='/accounts/cart' className='ml-20px relative mr-5'>
                            <i className='fa fa-shopping-cart' title='cart'></i>
                            {lstCartItems && (<span className='absolute w-4 h-4 rounded-full left-3 text-xs text-center text-white bg-pink-500' >{lstCartItems.length}</span>)}
                        </Link>
                        <label className='text-nowrap my-auto'>{UserName}</label>
                        <a className=''><i onMouseEnter={(e) => toggleSubmenu(e)} className='ml-10px fa fa-user-o' onMouseLeave={(e) => toggleSubmenu(e)}></i></a>
                        <ul className='text-left text-gray-600 text-sm c-top-submenu hidden mt-5 w-fit box-shadow shadow !bg-white right-6' onMouseLeave={(e) => { e.target.classList.toggle('hidden') }}>
                            {UserName ?
                                (<>
                                    <Link to='/accounts/profile' className='w-full block py-1.5 hover:text-yellow-600'><i className='fa fa-user !w-5'></i>Profile</Link>
                                    <Link to='/change_password' className='w-full block py-1.5 hover:text-yellow-600'><i className='fa fa-lock !w-5'></i>Change Password</Link>
                                    <Link to='/accounts/dashboard' className='w-full block py-1.5 hover:text-yellow-600'><i className='fa fa-home !w-5'></i>Dashboard</Link>
                                    <Link to='/accounts/orders' className='w-full block py-1.5 hover:text-yellow-600'><i className='fa fa-shopping-cart !w-5'></i>Orders</Link>
                                    <label className='py-1.5 hover:text-yellow-600' onClick={() => logout()}><i className='fa fa-sign-out !w-5'></i>Logout</label>
                                </>)
                                :
                                (
                                    <Link to='/registration' className='w-full block py-1 hover:text-yellow-600'>LogIn</Link>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}