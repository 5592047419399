import React, { createContext, useContext, useState } from 'react';


const NotificationContext = createContext();
export const useNotify = () => useContext(NotificationContext);

export default function Notify({ children }) {

    const [type, setType] = useState('');
    const [message, setMessage] = useState('');

    const notify = (Type, Message) => {
        setType(Type);
        setMessage(Message);

        const ModelNotify = document.getElementById('popup-Notification');
        if (ModelNotify) {
            ModelNotify.classList.remove('hidden');

            setTimeout(() => {
                ModelNotify.classList.add('hidden');
            }, 3000);
        }
    }

    return (
        <>
            <NotificationContext.Provider value={notify} >
                {children}
            </NotificationContext.Provider>

            <div id="popup-Notification" tabIndex="-1" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full mx-auto mt-16" onClick={(e) => { e.target.parentElement.classList.add('hidden') }}>
                    <div className="relative bg-white rounded-lg shadow-inner bg-gray-100">
                        <button type="button" style={{ height: '100px', width: '100px' }} className="mt-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white">
                            {type.toLowerCase() === 'error' ? error : Success}
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="hidden mx-auto mb-4 text-gray-400 w-12 h-12 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-300 text-gray-800">{message}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Success = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 335" width="300" height="335" preserveAspectRatio="xMidYMid meet" style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)', contentVisibility: 'visible' }}>
    <defs>
        <rect width="300" height="335" x="0" y="0"></rect>
    </defs>
    <g clipPath="url(#__lottie_element_896)">
        <g transform="matrix(-1.1008399724960327,0,0,-1.1008399724960327,145.5966339111328,157.64285278320312)" opacity="0.2" style={{ display: 'block' }}>
            <g opacity="1" transform="matrix(1,0,0,1,-4,-8.5)">
                <path fill="rgb(0,255,208)" fillOpacity="1" d=" M0,-119 C65.67610168457031,-119 119,-65.67610168457031 119,0 C119,65.67610168457031 65.67610168457031,119 0,119 C-65.67610168457031,119 -119,65.67610168457031 -119,0 C-119,-65.67610168457031 -65.67610168457031,-119 0,-119z"></path>
            </g>
        </g>
        <g transform="matrix(-0.9663900136947632,0,0,-0.9663900136947632,146.1344451904297,158.7856903076172)" opacity="0.4" style={{ display: 'block' }}>
            <g opacity="1" transform="matrix(1,0,0,1,-4,-8.5)">
                <path fill="rgb(0,221,179)" fillOpacity="1" d=" M0,-119 C65.67610168457031,-119 119,-65.67610168457031 119,0 C119,65.67610168457031 65.67610168457031,119 0,119 C-65.67610168457031,119 -119,65.67610168457031 -119,0 C-119,-65.67610168457031 -65.67610168457031,-119 0,-119z"></path>
            </g>
        </g>
        <g transform="matrix(0.7695679664611816,0,0,0.7695679664611816,153.07827758789062,173.54132080078125)" opacity="1" style={{ display: 'block' }}>
            <g opacity="1" transform="matrix(1,0,0,1,-4,-8.5)">
                <path fill="rgb(0,221,179)" fillOpacity="1" d=" M0,-119 C65.67610168457031,-119 119,-65.67610168457031 119,0 C119,65.67610168457031 65.67610168457031,119 0,119 C-65.67610168457031,119 -119,65.67610168457031 -119,0 C-119,-65.67610168457031 -65.67610168457031,-119 0,-119z"></path>
            </g>
        </g>
        <g transform="matrix(1.1142899990081787,0,0,1.1142899990081787,148,185.5)" opacity="1" style={{ display: 'block' }}>
            <g opacity="1" transform="matrix(0.9857856631278992,0.16800765693187714,-0.16800765693187714,0.9857856631278992,-4,7)">
                <path strokeOpacity="round" strokeLinejoin="round" fillOpacity="0" stroke="rgb(217,223,230)" strokeWidth="25" d=" M-46.07400131225586,-6.315000057220459 C-32.987998962402344,0.9570000171661377 -6.815000057220459,15.5 -6.815000057220459,15.5 C-6.815000057220459,15.5 27.427000045776367,-38.82400131225586 44.57099914550781,-66.0199966430664"></path>
            </g>
        </g>
    </g>
</svg>)

const error = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" preserveAspectRatio="xMidYMid meet" style={{ width: '100%', height: '100%', transform: 'translate3d(0px, 0px, 0px)', contentVisibility: 'visible' }}>
    <defs>
        <rect width="32" height="32" x="0" y="0"></rect>
    </defs>
    <g clipPath="url(#__lottie_element_1173)">
        <g transform="matrix(1.703474521636963,0,0,1.703474521636963,1.9463348388671875,1.9463348388671875)" opacity="0.27896151793614593" style={{ display: 'block' }}>
            <g opacity="1" transform="matrix(1,0,0,1,8.25,8.25)">
                <path fill="rgb(251,44,86)" fillOpacity="1" d=" M-8,0 C-8,4.415999889373779 -4.415999889373779,8 0,8 C4.415999889373779,8 8,4.415999889373779 8,0 C8,-4.415999889373779 4.415999889373779,-8 0,-8 C-4.415999889373779,-8 -8,-4.415999889373779 -8,0z"></path>
            </g>
        </g>
        <g transform="matrix(1.2638030052185059,0,0,1.2638030052185059,5.573625564575195,5.573625564575195)" opacity="1" style={{ display: 'block' }}>
            <g opacity="1" transform="matrix(1,0,0,1,8.25,8.25)">
                <path fill="rgb(251,44,86)" fillOpacity="1" d=" M-8,0 C-8,4.415999889373779 -4.415999889373779,8 0,8 C4.415999889373779,8 8,4.415999889373779 8,0 C8,-4.415999889373779 4.415999889373779,-8 0,-8 C-4.415999889373779,-8 -8,-4.415999889373779 -8,0z"></path>
            </g>
            <g opacity="1" transform="matrix(1,0,0,1,8.25,7.193999767303467)">
                <path fill="rgb(255,255,255)" fillOpacity="1" d=" M0,2.5439999103546143 C0.22100000083446503,2.5439999103546143 0.4000000059604645,2.365000009536743 0.4000000059604645,2.1440000534057617 C0.4000000059604645,2.1440000534057617 0.4000000059604645,-2.1440000534057617 0.4000000059604645,-2.1440000534057617 C0.4000000059604645,-2.365000009536743 0.22100000083446503,-2.5439999103546143 0,-2.5439999103546143 C-0.22100000083446503,-2.5439999103546143 -0.4000000059604645,-2.365000009536743 -0.4000000059604645,-2.1440000534057617 C-0.4000000059604645,-2.1440000534057617 -0.4000000059604645,2.1440000534057617 -0.4000000059604645,2.1440000534057617 C-0.4000000059604645,2.365000009536743 -0.22100000083446503,2.5439999103546143 0,2.5439999103546143z"></path>
            </g>
            <g opacity="1" transform="matrix(1,0,0,1,8.25,12.25)">
                <path fill="rgb(255,255,255)" fillOpacity="1" d=" M0,-0.800000011920929 C-0.44200000166893005,-0.800000011920929 -0.800000011920929,-0.4429999887943268 -0.800000011920929,0 C-0.800000011920929,0.4410000145435333 -0.44200000166893005,0.800000011920929 0,0.800000011920929 C0.44200000166893005,0.800000011920929 0.800000011920929,0.4410000145435333 0.800000011920929,0 C0.800000011920929,-0.4429999887943268 0.44200000166893005,-0.800000011920929 0,-0.800000011920929z"></path>
            </g>
        </g>
    </g>
</svg>);