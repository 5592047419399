import React from 'react';

import Slider from './Home/Slider';
import Categories from './Home/Categories';
import Products from './Home/Products';
import Blogs from './Home/Blogs';
import Reviews from './Home/Reviews';

export default function Home() {
  return (
    <>
      <Slider />
      <Categories />
      <Products />
      <Blogs />
      <Reviews />
    </>
  )
}
