import React, { useEffect, useState } from 'react'

export default function Dropdown({ className, Name, items, onSelectChange, Value, AddItem }) {
    const [search, setSearch] = useState('');
    const [Show, setShow] = useState(false);
    const [SelectedItem, setSelectedItem] = useState('Select Category');

    const loadItems = (item) => {
        return (item ? item.map(([itm, value], index) =>
            itm.toLowerCase().includes(search.toLowerCase()) && (<li key={index} onClick={() => setValue(value, itm)}>
                <div className="flex items-center ps-2 rounded hover:bg-gray-100 light:hover:bg-gray-600">
                    <label htmlFor="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded light:text-gray-300">{itm}</label>
                </div>
            </li>)
        ) : '');
    };

    const setValue = (value, Item) => {
        setShow(false);
        setSelectedItem(Item);
        onSelectChange(value);
    }

    useEffect(() => {
        const objItem = items.find((item) => item[1] === Value);

        if (objItem) {
            setValue(objItem[1], objItem[0]);
        }
        else {
            setValue(0, 'Select ' + Name);
        }
    }, [items]);

    return (
        <>
            <button id="dropdownSearchButton" onClick={() => setShow(!Show)} data-dropdown-toggle="dropdownSearch" data-dropdown-placement="bottom" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg px-5 py-2.5 flex items-center ${className} `} type="button">
                {SelectedItem}
                <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
            </button>

            <div className={`z-10 ${Show ? '' : 'hidden'} mt-2 absolute bg-white rounded-lg shadow light:bg-gray-700 ${className}`} onMouseLeave={() => setShow(false)}>
                <div className="p-3">
                    <label htmlFor="input-group-search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 light:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <input type="text" value={search} onChange={(e) => (setSearch(e.target.value))} id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-blue-500 light:focus:border-blue-500" placeholder="Search user" />
                    </div>
                </div>
                <ul className="px-3 pb-3 overflow-y-auto text-sm max-h-md text-gray-700 light:text-gray-200" aria-labelledby="dropdownSearchButton">
                    {AddItem !== 'False' && (<li onClick={() => setValue(0, 'Select ' + Name)}>
                        <div className="flex items-center ps-2 rounded hover:bg-gray-100 light:hover:bg-gray-600">
                            <label htmlFor="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded light:text-gray-300">Select {Name}</label>
                        </div>
                    </li>)}
                    {loadItems(items)}
                </ul>
            </div>

        </>
    )
}