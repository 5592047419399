import React from 'react'
import Sidebar from './Sidebar'
import { Route, Routes } from 'react-router'
import Dashboard from './Dashboard'
import Orders from './Orders'
import Profile from './Profile'
import Cart from './Cart'
import PageTitle from '../Shop/PageTitle'

export default function Accounts() {
    return (
        <div>
            <PageTitle Name='Accounts' />
            <div style={{ margin: '30px', width: 'calc(100% - 30px)' }}>
                <div className='row sm:flex'>
                    <Sidebar />
                    <Routes>
                        <Route path='/dashboard' element={<Dashboard />} />
                        <Route path='/orders' element={<Orders />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/cart' element={<Cart />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}
