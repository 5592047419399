import React, { useEffect } from 'react'

export default function Dropdown({ className, Name, items, onSelectChange, Value, DefaultSelect }) {
    const handleSelectChange = (event) => {
        onSelectChange(event.target.value);
    };

    const loadItems = (item) => {
        return (item ? item.map(([itm, value], index) => (
            <option key={index} value={value}>{itm}</option>
        )) : '');
    };

    useEffect(() => {
        if (DefaultSelect) {
            onSelectChange(items[0][1]);
        }
    }, []);
    return (
        <select className={`list-box form-control ${className}`} value={Value} onChange={handleSelectChange}>
            {Name && (<option value={0}>Select {Name}</option>)}
            {loadItems(items)}
        </select>
    )
}
