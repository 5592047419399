const SelectRow = (index, selectedRowIndex, setSelectedRow) => {
    if (index === selectedRowIndex) {
        setSelectedRow(-1);
    }
    else {
        setSelectedRow(index);
    }
}

const GetSelectedItem = (selectedRowIndex, Data) => {
    if (selectedRowIndex >= 0 && Data && Data.length > selectedRowIndex) {
        return Data[selectedRowIndex];
    }
    else {
        return false;
    }
}

export { SelectRow, GetSelectedItem }