import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../CSS/sidebar.css';

export default function Sidebar() {

  const [tab, setTab] = useState(0);

  return (
    <aside id="logo-sidebar"
      className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 xl:translate-x-0 light:bg-gray-800 light:border-gray-700"
      aria-label="Sidebar">
      <div className="h-full px-3 pb-4 overflow-y-auto bg-white light:bg-gray-800">
        <ul className="space-y-2 font-medium text-left">
          <li>
            <Link to='/admin/' onClick={() => setTab(0)} className={`flex items-center p-2 text-gray-900 rounded-lg light:text-white hover:bg-gray-100 light:hover:bg-gray-700 group ${tab === 0 ? 'bg-gray-300 ' : ''}`}>
              <svg className={`w-5 h-5 text-gray-500 transition duration-75 light:text-gray-400 group-hover:text-gray-900 light:group-hover:text-white ${tab === 0 ? 'text-gray-900 ' : ''}`}
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
              </svg>
              <span className="ms-3">Dashboard</span>
            </Link>
          </li>
          <li>
            <button type="button" onClick={() => setTab(2)} className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab >= 2 && tab <= 3 ? 'bg-gray-300 ' : ''}`}>
              <svg className={`flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 light:text-gray-400 group-hover:text-gray-900 light:group-hover:text-white ${tab === 2 ? 'text-gray-900 ' : ''}`}
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
              </svg>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Display Site</span>
              <svg className={`w-3 h-3 ${tab >= 2 && tab <= 3 ? 'rotate-180' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <ul className={`py-2 space-y-2 ${tab >= 2 && tab <= 3 ? '' : 'hidden'}`}>
              <li>
                <Link to='/admin/Slider' onClick={() => setTab(3)} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab === 3 ? 'bg-gray-200' : ''}`}>Slider</Link>
              </li>
            </ul>
          </li>
          <li>
            <button type="button" onClick={() => setTab(4)} className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab >= 4 && tab <= 8 ? 'bg-gray-300 ' : ''}`}>
              <svg className={`flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 light:text-gray-400 light:group-hover:text-white ${tab >= 4 && tab <= 8 ? 'text-gray-900 ' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
              </svg>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">E-commerce</span>
              <svg className={`w-3 h-3 ${tab >= 4 && tab <= 8 ? 'rotate-180' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <ul className={`py-2 space-y-2 ${tab >= 4 && tab <= 8 ? '' : 'hidden'}`}>
              <li>
                <Link to='/admin/orders' onClick={() => setTab(5)} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab === 5 ? 'bg-gray-200' : ''}`}>Orders</Link>
              </li>
              <li>
                <Link to='/admin/category' onClick={() => setTab(6)} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab === 6 ? 'bg-gray-200' : ''}`}>Category</Link>
              </li>
              <li>
                <Link to='/admin/material' onClick={() => setTab(7)} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab === 7 ? 'bg-gray-200' : ''}`}>Materials</Link>
              </li>
              <li>
                <Link to='/admin/diamonds' onClick={() => setTab(8)} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab === 8 ? 'bg-gray-200' : ''}`}>Diamonds</Link>
              </li>
            </ul>
          </li>
          <li>
            <button type="button" onClick={() => setTab(9)} className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab >= 9 && tab <= 10 ? 'bg-gray-300 ' : ''}`}>
              <svg className={`flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 light:text-gray-400 group-hover:text-gray-900 light:group-hover:text-white ${tab >= 9 && tab <= 10 ? 'text-gray-900 ' : ''}`}
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
              </svg>
              <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Products</span>
              <svg className={`w-3 h-3 ${tab >= 9 && tab <= 10 ? 'rotate-180' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
              </svg>
            </button>
            <ul className={`py-2 space-y-2 ${tab >= 9 && tab <= 10 ? '' : 'hidden'}`}>
              <li>
                <Link to='/admin/products' onClick={() => setTab(10)} className={`flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 light:text-white light:hover:bg-gray-700 ${tab === 10 ? 'bg-gray-200' : ''}`}>Products</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/admin/config/' onClick={() => setTab(11)} className={`flex items-center p-2 text-gray-900 rounded-lg light:text-white hover:bg-gray-100 light:hover:bg-gray-700 group ${tab === 11 ? 'bg-gray-300 ' : ''}`}>
              <i className={`fa  fa-cog w-5text-gray-500 transition duration-75 light:text-gray-400 group-hover:text-gray-900 light:group-hover:text-white ${tab === 11 ? 'text-gray-900 ' : ''}`}></i>
              <span className="ms-3">Configuration</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  )
}
