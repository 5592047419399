import { firestore, storage } from './firestore';
import { ref, uploadBytes, getDownloadURL } from '@firebase/storage';
import { getDocs, collection, query, where, doc, getDoc, addDoc, updateDoc, deleteDoc, Timestamp, serverTimestamp, onSnapshot } from '@firebase/firestore';
import { v4 } from 'uuid';


export const DataFetch = (Collection, Data, fun) => {
    try {
        let qry = collection(firestore, Collection);

        Object.keys(Data).forEach(field => {
            if (Data[field] !== null && Data[field] !== undefined) {
                qry = query(qry, where(field, '==', Data[field]));
            }
        });
        
        onSnapshot(qry, (doc) => {
            const data = [];
            doc.forEach(d => { data.push({ id: d.id, ...d.data() }); });
            fun(data);
        });
    } catch (error) {
        // console.log(error);
        return [];
    }
}

export const SelectData = async (Collection, Data) => {
    try {
        let qry = collection(firestore, Collection);

        Object.keys(Data).forEach(field => {
            if (Data[field] !== null && Data[field] !== undefined) {
                qry = query(qry, where(field, '==', Data[field]));
            }
        });

        const data = [];
        (await getDocs(qry)).forEach(doc => { data.push({ id: doc.id, ...doc.data() }); });

        return data;
    }
    catch {
        return [];
    }
}

export const SelectDataById = async (Collection, Id) => {
    try {
        let qry = doc(firestore, Collection, Id);

        const data = await getDoc(qry);

        return data.data();
    }
    catch {
        return [];
    }
}

export const InsertData = async (Collection, Data) => {
    try {
        const Id = await addDoc(collection(firestore, Collection), Data);
        return Id.id;
    } catch (error) {
        // console.log('error : ', error);
        return null;
    }
}

export const UpdateData = async (Collection, Id, Data) => {
    try {
        Data = filterData(Data);
        await updateDoc(doc(firestore, Collection, Id), Data);
        return Id;
    } catch (error) {
        return null;
    }
}

export const DeleteData = async (Collection, Id) => {
    try {
        await deleteDoc(doc(firestore, Collection, Id));
    } catch {

    }
}

const filterData = (Data) => {
    const filteredData = Object.fromEntries(
        Object.entries(Data).filter(([key, value]) => value)
    );

    return filteredData;
}

export const sortByField = (arr, fieldName) => {
    try {
        return arr.sort((a, b) => {
            if (a[fieldName] < b[fieldName]) return -1;
            if (a[fieldName] > b[fieldName]) return 1;
            return 0;
        });
    }
    catch {
        return arr;
    }
};

export const sortDescByField = (arr, fieldName) => {
    try {
        return arr.sort((a, b) => {
            if (a[fieldName] > b[fieldName]) return -1;
            if (a[fieldName] < b[fieldName]) return 1;
            return 0;
        });
    }
    catch {
        return arr;
    }
};

export const UploadFile = async (File, Path) => {
    try {
        let URL = `${Path}/${v4()}`;
        if (File) {
            if (File !== null && File !== undefined && File !== '') {
                const imgRef = ref(storage, URL);
                await uploadBytes(imgRef, File).then(() => {
                    // console.log("File uploaded Successfully.");
                }).catch((error) => {
                    // console.log('error occurs : ' + error);
                })
            }
        }

        return URL;
    }
    catch {
        return '';
    }
}

export const GetDownloadURL = async (URL) => {
    try {
        let url = '';
        if (URL !== null && URL !== '') {
            await getDownloadURL(ref(storage, URL)).then((uri) => {
                url = uri;
            }).catch((error) => {

            })
        }

        return url;
    }
    catch {
        return '';
    }
}

export const GetFile = async (URL) => {
    try {
        let file = null;
        if (URL !== null && URL !== '') {
            await getDownloadURL(ref(storage, URL)).then((uri) => {

                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                };

                xhr.open('GET', uri);
                file = xhr.send();
            }).catch((error) => {
                return null;
            })
        }

        return file;
    } catch (error) {
        return null;
    }
}

export const toTimeStamp = async (date) => {
    const firestoreTimestamp = Timestamp.fromDate(date);

    return firestoreTimestamp;
}

export const getTimeStampValue = async (timeStamp) => {
    const firestoreTimestamp = Timestamp.valueOf(timeStamp);

    return firestoreTimestamp;
}

export const GetTime = async () => {
    return serverTimestamp();
}