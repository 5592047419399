import React, { useEffect, useState } from 'react'
import { useData } from '../../Data'
import Item from './Item';

import NoData from '../../Images/No data-pana.png';

export default function Wishlist() {
    const { lstProduct, lstWishlist } = useData();

    const [Products, setProducts] = useState([]);

    useEffect(() => {
        const tmplstWishlist = lstProduct.filter(item => lstWishlist.includes(item.id));
        setProducts(tmplstWishlist);
    }, [lstWishlist]);

    return (
        <div>
            <h2 className='lg:text-3xl md:text-xl sm:text-md text-sm my-10 italic'>wishlist</h2>

            {Products && Products.length > 0 ? (<div className='grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2 md:gap-4 mt-4 md:mt-8'>
                {Products.map((product) => (<div key={product.id} className=''><Item product={product} /></div>))}
            </div>)
                : (
                    <div className='mb-32'>
                        <div className='mx-auto w-64 h-64'>
                            <img className='' src={NoData} alt='not found'></img>
                        </div>
                        <h1 className='text-lg font-semibold italic'>Wishlist Items Not Found</h1>
                    </div>
                )}
        </div>
    )
}
