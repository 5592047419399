import './App.css';
import './CSS/style.css';
import './CSS/style2.css';

import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoginPanel from './Login/LoginPanel';
import Registration from './Login/Registration';
import MainPage from './website/MainPage';
import AdminPanel from './admin/AdminPanel';
import NotFounds from './Login/NotFound';
import Notify from './website/Components/Notify';

function App() {

  useEffect(() => {
    // Initialize AOS
    if (window.AOS) {
      window.AOS.init({
        startEvent: 'DOMContentLoaded',
        debounceDelay: 50,
        throttleDelay: 99,

        offset: 150,
        delay: 200,
        duration: 1000,
        once: false,
      });
    }
  }, []);

  return (
    <div className="App max-w-full overflow-hidden">
      <Notify>
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<MainPage />} />
            <Route path='/registration/*'>
              <Route path='' element={<LoginPanel />} />
              <Route path='sign-up' element={<Registration />} />
            </Route>
            <Route path='/admin/*' element={<AdminPanel />} />
            <Route path='*' element={<NotFounds />} />
            {/* <Route path='/*' element={<AdminPanel />} /> */}
          </Routes>
        </BrowserRouter>
      </Notify>

    </div>
  );
}

export default App;