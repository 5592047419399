import React, { useEffect, useState } from 'react';

import G1 from '../../Images/Review/G1.jpg';
import G2 from '../../Images/Review/G2.jpg';
import G3 from '../../Images/Review/G3.jpg';
import G4 from '../../Images/Review/G4.jpg';

import Insta1 from '../../Images/insta1.jpg';
import Insta2 from '../../Images/insta2.jpg';
import Insta3 from '../../Images/insta3.jpg';
import Insta4 from '../../Images/insta4.jpg';
import Insta5 from '../../Images/insta5.jpg';
import Insta6 from '../../Images/insta6.jpg';

import insta from '../../Images/insta.svg';

export default function Reviews() {

  const [current, setCurrent] = useState(0);

  let timeout;

  useEffect(() => {
    timeout = setTimeout(() => {
      setCurrent((current + 1) % 4);
    }, 5000);
  }, [current]);

  const updateCurrent = (value) => {
    clearTimeout(timeout);
    setCurrent(value % 4);
  }

  return (
    <>
      <div className='bg-pink-50 py-5' data-aos='fade-down'>
        <div className='pt-3 md:pt-8 sm:pt-5'>
          <h2 className='text-2xl font-400'>Customers Reviews</h2>
        </div>
        <div className='mt-3 relative w-[100vw]'>

          <div className='absolute top-[50%] md:ml-[16%] sm:ml-[12%] xs:ml-[8%] ml-[6%] z-10' onClick={() => updateCurrent(current - 1)}>
            <label className='w-24 h-24 rounded-full cursor-pointer border border-black p-4 hover:scale-105 hover:bg-black hover:text-white'><i className='fa fa-arrow-left'></i></label>
          </div>

          <div className='absolute top-[50%] md:ml-[80%] sm:ml-[80%] xs:ml-[80%] ml-[85%] z-10' onClick={() => updateCurrent(current + 1)}>
            <label className='w-24 h-24 rounded-full border cursor-pointer border-black p-4 hover:scale-105 hover:bg-black hover:text-white'><i className='fa fa-arrow-right'></i></label>
          </div>

          <div className='overflow-hidden'>
            <div className='w-[60%] mx-auto'>
              <div className={`flex -translate-x-${current}/4 duration-1000`} style={{ width: '400%' }}>

                <div className={`p-5 m-2 sm:p-5 md:p-12 lg:p-16 sm:m-5 md:m-12 lg:m-14 md:flex w-1/4 duration-1000 ${current === 0 ? 'bg-white' : 'opacity-30'}`}>
                  <div className='w-full md:max-w-fit  min-w-fit'>
                    <img className='border-black border mx-auto p-1 rounded-full w-16 h-16 md:w-24 md:h-24' alt='Micheal Gough' loading='lazy' src={G1} ></img>
                  </div>
                  <div className='md:pl-10 mt-2 sm:mt-0'>
                    <p className='text-justify inline-flex'>I'm so glad I found All Jewelz! Their website is easy to navigate, and they have a wide selection of beautiful jewelry at affordable prices.</p>
                    <h3 className='text-pink-400 text-left pt-3'>Micheal Gough</h3>
                  </div>
                </div>

                <div className={`p-5 m-2 sm:p-5 md:p-12 lg:p-16 sm:m-5 md:m-12 lg:m-14 md:flex w-1/4 duration-1000 ${current === 1 ? 'bg-white' : 'opacity-30'}`}>
                  <div className='w-full md:max-w-fit  min-w-fit'>
                    <img className='border-black border mx-auto p-1 rounded-full w-16 h-16 md:w-24 md:h-24' alt='Bonnie Green' loading='lazy' src={G2} ></img>
                  </div>
                  <div className='md:pl-10 mt-2 sm:mt-0'>
                    <p className='text-justify inline-flex'>I've been a loyal customer of All Jewelz for years, and I can't say enough good things about them.</p>
                    <h3 className='text-pink-400 text-left pt-3'>Bonnie Green</h3>
                  </div>
                </div>

                <div className={`p-5 m-2 sm:p-5 md:p-12 lg:p-16 sm:m-5 md:m-12 lg:m-14 md:flex w-1/4 duration-1000 ${current === 2 ? 'bg-white' : 'opacity-30'}`}>
                  <div className='w-full md:max-w-fit  min-w-fit'>
                    <img className='border-black border mx-auto p-1 rounded-full w-16 h-16 md:w-24 md:h-24' alt='Roberta Casas' loading='lazy' src={G3} ></img>
                  </div>
                  <div className='md:pl-10 mt-2 sm:mt-0'>
                    <p className='text-justify inline-flex'>All Jewelz is my go-to for stunning jewelry pieces! The quality is impeccable, and their customer service is top-notch.</p>
                    <h3 className='text-pink-400 text-left pt-3'>Roberta Casas</h3>
                  </div>
                </div>

                <div className={`p-5 m-2 sm:p-5 md:p-12 lg:p-16 sm:m-5 md:m-12 lg:m-14 md:flex w-1/4 duration-1000 ${current === 3 ? 'bg-white' : 'opacity-30'}`}>
                  <div className='w-full md:max-w-fit min-w-fit'>
                    <img className='border-black border mx-auto p-1 rounded-full w-16 h-16 md:w-24 md:h-24' alt='Lydia Dokidis' loading='lazy' src={G4} ></img>
                  </div>
                  <div className='md:pl-10 mt-2 sm:mt-0'>
                    <p className='text-justify inline-flex'>Absolutely in love with the jewelry I received from All Jewelz! The craftsmanship is exceptional, and I've received so many compliments whenever I wear their pieces.</p>
                    <h3 className='text-pink-400 text-left pt-3'>Lydia Dokidis</h3>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 md:mt-24" data-aos='fade-bottom'>
        <h2 className="2xl:text-3xl lg:text-2xl sm:text-1xl text-xl uppercase text-gray-800  2xl:mb-10 md:mb-8 mb-4 leading-tight text-center px-4">Join us on &nbsp;
          <a className="underline decoration-1 hover:text-violet-900 underline-offset-4" href="#">@all_jewelz</a>
        </h2>
        <div className="relative flex flex-wrap mt-10 md:mt-14">
          <div className="insta-post w-1/2 sm:w-1/3 lg:w-1/6 ">
            <a className="relative group/insta block" href="/?ver=1717261731#">
              <img alt="instagram" title="instagram" loading="lazy" width="384" height="286" decoding="async" data-nimg="1" className="lg:h-56 h-44 object-cover" src={Insta1} style={{ color: 'transparent' }} />
              <div className="hover-stake absolute w-full h-full flex items-center justify-center bg-[#00000080] opacity-0 group-hover/insta:opacity-100 top-0 left-0">
                <img alt="instagram" title="instagram" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={insta} style={{ color: 'transparent' }} />
              </div>
            </a>
          </div>
          <div className="insta-post w-1/2 sm:w-1/3 lg:w-1/6 ">
            <a className="relative group/insta block" href="/?ver=1717261731#">
              <img alt="instagram" title="instagram" loading="lazy" width="384" height="286" decoding="async" data-nimg="1" className="lg:h-56 h-44 object-cover" src={Insta2} style={{ color: 'transparent' }} />
              <div className="hover-stake absolute w-full h-full flex items-center justify-center bg-[#00000080] opacity-0 group-hover/insta:opacity-100 top-0 left-0">
                <img alt="instagram" title="instagram" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={insta} style={{ color: 'transparent' }} />
              </div>
            </a>
          </div>
          <div className="insta-post w-1/2 sm:w-1/3 lg:w-1/6 ">
            <a className="relative group/insta block" href="/?ver=1717261731#">
              <img alt="instagram" title="instagram" loading="lazy" width="384" height="286" decoding="async" data-nimg="1" className="lg:h-56 h-44 object-cover" src={Insta3} style={{ color: 'transparent' }} />
              <div className="hover-stake absolute w-full h-full flex items-center justify-center bg-[#00000080] opacity-0 group-hover/insta:opacity-100 top-0 left-0">
                <img alt="instagram" title="instagram" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={insta} style={{ color: 'transparent' }} />
              </div>
            </a>
          </div>
          <div className="insta-post w-1/2 sm:w-1/3 lg:w-1/6 ">
            <a className="relative group/insta block" href="/?ver=1717261731#">
              <img alt="instagram" title="instagram" loading="lazy" width="384" height="286" decoding="async" data-nimg="1" className="lg:h-56 h-44 object-cover" src={Insta4} style={{ color: 'transparent' }} />
              <div className="hover-stake absolute w-full h-full flex items-center justify-center bg-[#00000080] opacity-0 group-hover/insta:opacity-100 top-0 left-0">
                <img alt="instagram" title="instagram" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={insta} style={{ color: 'transparent' }} />
              </div>
            </a>
          </div>
          <div className="insta-post w-1/2 sm:w-1/3 lg:w-1/6 ">
            <a className="relative group/insta block" href="/?ver=1717261731#">
              <img alt="instagram" title="instagram" loading="lazy" width="384" height="286" decoding="async" data-nimg="1" className="lg:h-56 h-44 object-cover" src={Insta5} style={{ color: 'transparent' }} />
              <div className="hover-stake absolute w-full h-full flex items-center justify-center bg-[#00000080] opacity-0 group-hover/insta:opacity-100 top-0 left-0">
                <img alt="instagram" title="instagram" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={insta} style={{ color: 'transparent' }} />
              </div>
            </a>
          </div>
          <div className="insta-post w-1/2 sm:w-1/3 lg:w-1/6 ">
            <a className="relative group/insta block" href="/?ver=1717261731#">
              <img alt="instagram" title="instagram" loading="lazy" width="384" height="286" decoding="async" data-nimg="1" className="lg:h-56 h-44 object-cover" src={Insta6} style={{ color: 'transparent' }} />
              <div className="hover-stake absolute w-full h-full flex items-center justify-center bg-[#00000080] opacity-0 group-hover/insta:opacity-100 top-0 left-0">
                <img alt="instagram" title="instagram" loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={insta} style={{ color: 'transparent' }} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
