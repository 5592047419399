import React, { useMemo } from 'react';

import Item from '../Shop/Item';
import { useData } from '../../Data';

export default function RelatedItems({ category }) {
  const { lstProduct } = useData();

  const loadRelatedProducts = useMemo(() => {
    return () => {
      const lstRelatedProducts = lstProduct.filter(product => product.Category === category);

      return lstRelatedProducts.slice(0, 4).map((product, index) => (
        <div key={index} className=''>
          <Item product={product} />
        </div>
      ))
    };
  }, [category]);

  return (
    <div id="primary" className="md:mt-10 mt-8 pls-content-area col-lg-12 col-md-12 col-lg-12 col-xl-12">
      <div className='lg:p-50px md:p-40px sm:p-30px xs:p-20px p-10px'>
        <a className="lg:text-2xl text-xl">
          <span>RELATED PRODUCTS</span>
        </a>
        <div className="products grid lg:grid-cols-4 md:grid-cols-2 product-style-1 flex grid-view col-lg" style={{ overflow: 'hidden' }}>
          {loadRelatedProducts()}
        </div>
        <div>
          <button className='Read-more'>See More</button>
        </div>
      </div>
    </div>
  )
}
