import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { GetSelectedItem, SelectRow } from '../../../Database/helper'
import { DeleteData, SelectData, sortByField } from '../../../Database/db_helper'
import { dbCollections } from '../../../Database/tables'

import Btn from '../common/btn'
import { useAlert } from '../common/message'
import { eMsg } from '../common/enum'

import Navbar from '../Navbar'

import ModelProduct from './Models/ModelProduct'

export default function Products() {
  const showMsg = useAlert();
  const navigate = useNavigate();

  const [model, setModel] = useState('');
  const [ProductData, setProductData] = useState([]);
  const [lstCategory, setCategory] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [searchText, setSearchText] = useState('');

  //#region Header Buttons

  const AddProduct = () => {
    setModel(<ModelProduct product={null} onClose={onClose} />);
  }

  const EditProduct = () => {
    const objProduct = GetSelectedItem(selectedRow, ProductData);
    if (objProduct) {
      setModel(<ModelProduct product={objProduct} onClose={onClose} />);
    }
    else {
      showMsg(eMsg.Info, 'No Product is selected.');
    }
  }

  const DeleteProduct = async () => {
    const objProduct = GetSelectedItem(selectedRow, ProductData);
    if (objProduct) {
      await DeleteData(dbCollections.Product, objProduct.id);
      showMsg(eMsg.Success, 'Product Deleted Successfully.');
    }
    else {
      showMsg(eMsg.Info, 'No Product is selected.');
    }
  }

  const onClose = () => {
    setModel('');
    ResetData();
  }

  //#endregion


  //#region  Load Data

  useEffect(() => { ResetData(); }, []);

  const ResetData = async () => {
    const dtProduct = await SelectData(dbCollections.Product, {});

    sortByField(dtProduct, 'InsertTime');
    setProductData(dtProduct);

    const dtCategory = await SelectData(dbCollections.Category, {});
    setCategory(dtCategory);
  }

  const renderData = () => {
    return ProductData.map((objProduct, Index) => {

      const objCategory = lstCategory.find(cate => cate.id === objProduct.Category);
      if (searchText === '' || objProduct.ProductName.toLowerCase().includes(searchText.toLowerCase()) || (objCategory && objCategory.Category.toLowerCase().includes(searchText.toLowerCase()))) {
        return (<tr key={Index} onClick={() => SelectRow(Index, selectedRow, setSelectedRow)} onDoubleClick={() => GetDiamondDetail(objProduct)} className={`bg-white border-b light:bg-gray-800 light:border-gray-700 hover:bg-blue-100 light:hover:bg-gray-600 ${selectedRow === Index ? 'grid-row-selected' : ''}`}>
          <td className="px-6 py-4 w-20">{Index + 1}</td>
          <td className="px-6 py-4">{objProduct.ProductName}</td>
          <td className="px-6 py-4">{objCategory && objCategory.Category} </td>
          <td className="px-6 py-4 w-f-content">
            <table className='text-center'>
              <thead>
                <tr>
                  <th className='pr-3'>Size</th>
                  <th>Material Weight</th>
                </tr>
              </thead>
              <tbody>
                {objProduct.MaterialDetails.map((material, index) => (
                  <tr key={index}>
                    <td className='pr-3'>{material.Size}</td>
                    <td>{material.MaterialWeight}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
          <td className="px-6 py-4">{objProduct.Description} </td>
        </tr>
        )
      }
    });
  }

  const GetDiamondDetail = (Product) => {
    navigate('diamond_detail', { state: Product });
  }

  //#endregion

  return (
    <>
      <Navbar Name='Products' onSearch={setSearchText} />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className='flex'>
          <Btn onClick={AddProduct} Name='Add' Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500'>
            <i className='fa fa-plus mr-1'></i>
          </Btn>
          <Btn onClick={EditProduct} Name='Edit' Class='text-white bg-purple-700 hover:bg-purple-500 focus:ring-purple-200 light:bg-purple-300 light:hover:bg-purple-500 light:focus:ring-purple-500'>
            <i className='fa fa-edit mr-1'></i>
          </Btn>
          <Btn onClick={DeleteProduct} Name='Delete' Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500'>
            <i className='fa fa-ban mr-1'></i>
          </Btn>
        </div>
        <div className='overflow-auto' style={{ height: 'calc(100vh - 14.5rem)' }}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 light:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">SrNo</th>
                <th scope="col" className="px-6 py-3">Product</th>
                <th scope="col" className="px-6 py-3">Category</th>
                <th scope="col" className="px-6 py-3">Material</th>
                <th scope="col" className="px-6 py-3">About</th>
              </tr>
            </thead>
            <tbody>
              {renderData()}
            </tbody>
          </table>
        </div>
        {/* <nav className="flex items-center flex-column md:flex-row justify-between pt-4" aria-label="Table navigation">
          <span className="text-sm font-normal text-gray-500 light:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto text-left ml-2">Showing <span className="font-semibold text-gray-900 light:text-white">1-10</span> of <span className="font-semibold text-gray-900 light:text-white">1000</span></span>
          <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
            <li>
              <button className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 light:bg-gray-800 light:border-gray-700 light:text-gray-400 light:hover:bg-gray-700 light:hover:text-white">Previous</button>
            </li>
            <li>
              <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 light:bg-gray-800 light:border-gray-700 light:text-gray-400 light:hover:bg-gray-700 light:hover:text-white">1</button>
            </li>
            <li>
              <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 light:bg-gray-800 light:border-gray-700 light:text-gray-400 light:hover:bg-gray-700 light:hover:text-white">2</button>
            </li>
            <li>
              <button href="#" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 light:border-gray-700 light:bg-gray-700 light:text-white">3</button>
            </li>
            <li>
              <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 light:bg-gray-800 light:border-gray-700 light:text-gray-400 light:hover:bg-gray-700 light:hover:text-white">4</button>
            </li>
            <li>
              <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 light:bg-gray-800 light:border-gray-700 light:text-gray-400 light:hover:bg-gray-700 light:hover:text-white">5</button>
            </li>
            <li>
              <button href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 light:bg-gray-800 light:border-gray-700 light:text-gray-400 light:hover:bg-gray-700 light:hover:text-white">Next</button>
            </li>
          </ul>
        </nav> */}
      </div>
      {model}
    </>
  )
}
