import React from 'react';

// import '../../CSS/Pages/Home/Categories.css';

import { useData } from '../../Data';
import { useNavigate } from 'react-router';

export default function Categories() {
  const navigate = useNavigate();
  const { lstCategory } = useData();


  const loadCategories = () => {
    const tmplstCategory = lstCategory.filter(cate => cate.ParentId === '0')
    return <div className={`grid md:grid-cols-${tmplstCategory.length} sm:grid-cols-3 xs:grid-cols-2 grid-cols-2 gap-6 px-10 md:px-32 py-8 md:py-16`}>
      {tmplstCategory.map((category) => category.SizeIconDownloadURL && (
        <div className='hover:text-[#CA8282] cursor-pointer' onClick={() => navigate(`/shop?category=${category.id}`)}>
          <div className='mb-4 hover:scale-110'>
            <img src={category.SizeIconDownloadURL} alt={category.Category} loading="lazy"  className='mx-auto border border-gray-100 rounded-full w-18 lg:w-48 md:w-32 sm:w-24 xs:w-18' />
          </div>
          <label className='cursor-pointer'>{category.Category}</label>
        </div>
      ))}
    </div>
  }
  return (
    <div>
      {loadCategories()}
    </div>
  )
}
