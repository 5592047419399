import React from 'react';

import { useData } from '../../Data';

export default function Sidebar({ onCategoryChange, selectedCategories }) {
    const { lstCategory } = useData();

    const GetSubCategories = (Categories) => {
        return Categories.map((Category) => {
            const subCategory = lstCategory.filter(cate => cate.ParentId === Category.id);
            return (<li key={Category.id} className='mt-2'>
                {
                    subCategory.length > 0 ?
                        (<>
                            <input type='checkbox' checked={selectedCategories.find(cate => cate === Category.id)} onChange={() => onCategoryChange(Category.id)}></input>
                            <a className='ml-2'>{Category.Category}</a>
                            <span className="fa fa-angle-down float-right" onClick={(e) => toggleSubmenu(e)}></span>
                            <ul className={`children ml-3 hidden`}>
                                {GetSubCategories(subCategory)}
                            </ul>
                        </>)
                        :
                        (<>
                            <input type='checkbox' checked={selectedCategories.find(cate => cate === Category.id)} onChange={() => onCategoryChange(Category.id)}></input>
                            <a className='ml-2'>{Category.Category}</a>
                        </>)
                }
            </li>)
        });
    }

    const renderCategories = () => {
        const MainCategories = lstCategory.filter(cate => cate.ParentId === '0');

        return MainCategories.map((Category) => {
            const subCategory = lstCategory.filter(cate => cate.ParentId === Category.id);
            return (<li key={Category.id} className='mt-2'>
                {
                    subCategory.length > 0 ?
                        (<>
                            <input type='checkbox' id='chk' checked={selectedCategories.find(cate => cate === Category.id)} onChange={() => onCategoryChange(Category.id)}></input>
                            <a for='chk' className='ml-2'>{Category.Category}</a>
                            <span className="fa fa-angle-down float-right" onClick={(e) => toggleSubmenu(e)}></span>
                            <ul className={`children ml-3 h-0 transition-transform overflow-hidden`}>
                                {GetSubCategories(subCategory)}
                            </ul>
                        </>)
                        :
                        (<>
                            <input type='checkbox' checked={selectedCategories.find(cate => cate === Category.id)} onChange={() => onCategoryChange(Category.id)}></input>
                            <a className='ml-2'>{Category.Category}</a>
                        </>)
                }
            </li>)
        });
    }

    // Function to toggle the submenu visibility
    const toggleSubmenu = (e) => {
        if (e) {
            e.target.classList.toggle('fa-angle-up');
            e.target.classList.toggle('fa-angle-down');
        }
        const ul = e.target.nextElementSibling;
        if (ul) {
            ul.classList.toggle('h-0');
        }
    }

    return (
        <div id="secondary" className="pls-widget-area col-12 col-md-4 col-lg-3 col-xl-3 text-left">
            <div className="sidebar-inner">
                <div id="woocommerce_product_categories-2" className="widget woocommerce widget_product_categories widget-toggle">
                    <h3 className="text-xl font-semibold">Categories</h3>
                    <ul className="product-categories mt-5 ">
                        {renderCategories()}
                    </ul>
                </div>
            </div>
        </div>
    )
}
