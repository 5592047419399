import React from 'react'

export default function Shipping_delivery_policy() {
  return (
    <div className='lg:p-24 md:p-20 sm:p-14 p-10 text-justify'>
      AllJewelz (“we” and “us”) is the operator of (https://web.alljewelz.com/) (“Website”). By placing an order through this Website you will be agreeing to the terms below. These are provided to ensure both parties are aware of and agree upon this arrangement to mutually protect and set expectations for our service.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>DELIVERY TERMS</h1>
      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Transit Time Domestically</h3>
      In general, domestic shipments are in transit for 5 – 7 days.

      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Dispatch Time</h3>
      Orders are usually dispatched within 2 business days of payment of the order.

      Our warehouse operates Monday – Friday during standard business hours, except on national holidays at which time the warehouse will be closed. In these instances, we take steps to ensure shipment delays will be kept to a minimum.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>GENERAL</h1>
      Subject to stock availability. We try to maintain accurate stock counts on our website but from time to time there may be a stock discrepancy and we will not be able to fulfill all your items at the time of purchase. In this instance, we will fulfill the available products to you, and contact you about whether you would prefer to await restocking of the back ordered item or if you would prefer for us to process a refund.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>SHIPPING COSTS</h1>
      Shipping costs are calculated during checkout based on the weight, dimensions, and destination of the items in the order. Payment for shipping will be collected with the purchase.

      This price will be the final price for shipping cost to the customer.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>RETURNS</h1>
      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Return Due To Change of Mind</h3>
      At AllJewelz, our priority is delivering fresh and premium-quality products to our customers. As a result, we can only accept returns or exchanges for damaged or incorrectly shipped items. If your order is received damaged or with a wrong product, kindly notify us with a photo within 24 hours of delivery. Please ensure the products are returned in their original packaging, unused, and in resalable condition. Return shipping for damaged or wrongly shipped items will be covered by AllJewelz, and a refund will be issued in the form of store credit for future purchases. Your satisfaction is our utmost concern, and we are committed to resolving any issues promptly.

      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Change of Delivery Address</h3>
      For a change of delivery address requests, we are able to change the address at any time before the order has been dispatched.

      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Delivery Time Exceeded</h3>
      If the delivery time has exceeded the forecasted time, please contact us so that we can conduct an investigation.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>PARCELS DAMAGED IN TRANSIT</h1>
      If you find a parcel is damaged in transit, if possible, please reject the parcel from the courier and get in touch with our customer service. If the parcel has been delivered without you being present, please contact customer service with the next steps.

      <h1 className='text-xl text-pink-600 mb-3'>DUTIES & TAXES</h1>
      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Sales Tax</h3>
      Sales tax has already been applied to the price of the goods as displayed on the website.

      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Import Duties & Taxes</h3>
      Import duties and taxes for international shipments may be liable to be paid upon arrival in the destination country. This varies by country, and AllJewelz encourages you to be aware of these potential costs before placing an order with us.

      If you refuse to pay duties and taxes upon arrival at your destination country, the goods will be returned to AllJewelz at the customer’s expense, and the customer will receive a refund for the value of the goods paid, minus the cost of the return shipping. The cost of the initial shipping will not be refunded.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>CANCELLATIONS</h1>
      As a part of our commitment to delivering high-quality products, we begin preparing your order as soon as it is placed. Due to this, we can only accept cancellations within 1 day of order placement. Once an order has been prepared, we are unable to process cancellations. We appreciate your understanding and apologize for any inconvenience caused. For further assistance, please refer to our refund policy.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>INSURANCE</h1>
      Parcels are insured for loss and damage up to the value stated by the courier.

      <h1 className='text-xl text-pink-600 mt-5 mb-3'>Process for parcel damaged in-transit</h1>
      We will process a refund or replacement as soon as the courier has completed their investigation into the claim.

      <h3 className='text-md italic text-pink-600 mt-3 mb-1'>Import Duties & Taxes</h3>
      We will process a refund or replacement as soon as the courier has conducted an investigation and deemed the parcel lost.
    </div>
  )
}
