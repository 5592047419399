import React from 'react'
import { Link } from 'react-router-dom';

import logo from '../../website/Images/logo/logo.png';

export default function Header() {

  const toggleSidebar = () => {
    const sidebar = document.getElementById('logo-sidebar');
    sidebar.classList.toggle('-translate-x-full');
  }
  
  const toggleProfile = () => {
    const sidebar = document.getElementById('formProfile');
    sidebar.classList.toggle('hidden');
  }

  return (

    <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 light:bg-gray-800 light:border-gray-700">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start rtl:justify-end">
            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" onClick={() => toggleSidebar()}
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 light:text-gray-400 light:hover:bg-gray-700 light:focus:ring-gray-600">
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path clipRule="evenodd" fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
                </path>
              </svg>
            </button>
            <a href="#" className="flex ms-2 md:me-24">
              <img src={logo} loading='lazy' className="h-12 me-3" alt="FlowBite Logo" />
            </a>
          </div>
          <div className="dropdown dropdown-end relative">
            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full" onClick={() => toggleProfile()}>
                <img alt="Tailwind CSS Navbar component" loading='lazy' src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" />
              </div>
            </div>
            <ul tabIndex={0} id='formProfile' onMouseLeave={() => toggleProfile()} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content rounded-box w-52 bg-gray-200 absolute right-0 hidden">
              <li><Link to='/'>Logout</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
