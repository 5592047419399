import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Data from './Data';

import Header from './Header';
import Footer from './Footer';

import HomePage from './Pages/Home';
import Shop from './Pages/Shop';
import Product from './Pages/Product';
import ContactUs from './Pages/ContactUs';
import Wishlist from './Pages/Shop/Wishlist';
import Accounts from './Pages/MyAccount/Accounts';
import ChangePassword from '../Login/ChangePassword';
import PrivacyPolicy from './Pages/Informations/privacy_policy';
import ShippingDeliveryPolicy from './Pages/Informations/shipping_delivery_policy';
import TermsConditions from './Pages/Informations/terms_conditions';
import RefundReturnPolicy from './Pages/Informations/refund_return_policy';

export default function MainPage() {
    return (
        <Data>
            <Routes>
                <Route path='/change_password' element={<ChangePassword />} />
                <Route path='*' element={<DefaultLayout />} />
            </Routes>
        </Data>
    )
}

function DefaultLayout() {
    return (
        <>
            <Header />
            <Routes>
                <Route path='' element={<HomePage />} />
                <Route path='/home' element={<HomePage />} />
                <Route path='/shop' element={<Shop />} />
                <Route path='/product' element={<Product />} />
                <Route path='/contactus' element={<ContactUs />} />
                <Route path='/wishlist' element={<Wishlist />} />
                <Route path='/accounts/*' element={<Accounts />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/shipping-delivery-policy' element={<ShippingDeliveryPolicy />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
                <Route path='/refund-return-policy' element={<RefundReturnPolicy />} />
            </Routes>
            <Footer />
        </>
    );
}