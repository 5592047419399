import React, { useState } from 'react';
import Cookies from 'js-cookie';
import * as Yup from 'yup';

import { firestore } from '../Database/firestore';
import { getDocs, collection, query, where, or, and } from '@firebase/firestore';
import { dbCollections } from '../Database/tables';
import { IsEmail, IsEmpty, IsPhone } from '../Database/validation';
import { useNavigate } from 'react-router';
import { useNotify } from '../website/Components/Notify';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { TextField } from '@mui/material';


const LoginSchema = Yup.object().shape({
  phone_email: Yup.string().required('This field is required')
    .test(
      'is-phone-or-email',
      'Must be a valid phone number or email address',
      function (value) {
        const phoneRegex = /^[0-9]{10}$/; // Adjust this regex as needed
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return phoneRegex.test(value) || emailRegex.test(value);
      }
    ),
  password: Yup.string().required('password is required.'),
});


export default function LoginPanel() {
  const navigate = useNavigate();
  const notify = useNotify();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState('');
  const [erremail, seterremail] = useState('');
  const [errpassword, seterrpassword] = useState('');

  const handleSubmit = async (values) => {
    if (IsEmpty(values.phone_email) || (!IsEmail(values.phone_email) && !IsPhone(values.phone_email))) {
      notify('error', 'Enter valid Email address or Phone Number');
      return;
    }

    if (IsEmpty(values.password)) {
      notify('error', 'Enter Password.');
      return;
    }

    if (values.phone_email === 'AllJewelz@gmail.com' && values.password === '123@admin') {
      let expireTime = '';
      if (rememberMe) {
        expireTime = new Date();
        expireTime.setTime(expireTime.getTime() + (1000 * 3600 * 24 * 2));
      }

      Cookies.set('IsAdmin', 'TRUE', { expires: expireTime, path: '/' });
      navigate('/admin');
    }
    else {
      const qry = query(collection(firestore, dbCollections.Customers), and(or(where('email', '==', values.phone_email.toLowerCase()), where('phone_number', '==', values.phone_email)), where('password', '==', values.password)));
      const data = [];
      (await getDocs(qry)).forEach(doc => { data.push({ id: doc.id, ...doc.data() }); });

      if (data.length > 0) {
        console.log(data);

        let expireTime = '';
        if (rememberMe) {
          expireTime = new Date();
          expireTime.setTime(expireTime.getTime() + (1000 * 3600 * 24 * 120));
        }

        Cookies.set('CustomerId', data[0].id, { expires: expireTime, path: '/' });
        Cookies.set('CustomerName', data[0].name, { expires: expireTime, path: '/' });

        navigate('/');

      }
      else {
        notify('error', 'Email address or Phone Number and password is incorrect.');
      }
    }
  }

  // const LogIn = async (event) => {
  //   event.preventDefault();

  //   if (IsEmpty(email) || (!IsEmail(email) && !IsPhone(email))) {
  //     seterremail('Enter valid Email address or Phone Number');
  //     return;
  //   }
  //   else {
  //     seterremail('');
  //   }

  //   if (IsEmpty(password)) {
  //     seterrpassword('Enter Password.');
  //     return;
  //   }
  //   else {
  //     seterrpassword('');
  //   }

  //   if (email === 'AllJewelz@gmail.com' && password === '123@admin') {
  //     let expireTime = '';
  //     if (rememberMe) {
  //       expireTime = new Date();
  //       expireTime.setTime(expireTime.getTime() + (1000 * 3600 * 24 * 2));
  //     }

  //     Cookies.set('IsAdmin', 'TRUE', { expires: expireTime, path: '/' });
  //     navigate('/admin');
  //   }
  //   else {
  //     const qry = query(collection(firestore, dbCollections.Customers), and(or(where('email', '==', email.toLowerCase()), where('phone_number', '==', email)), where('password', '==', password)));
  //     const data = [];
  //     (await getDocs(qry)).forEach(doc => { data.push({ id: doc.id, ...doc.data() }); });

  //     if (data.length > 0) {
  //       console.log(data);

  //       let expireTime = '';
  //       if (rememberMe) {
  //         expireTime = new Date();
  //         expireTime.setTime(expireTime.getTime() + (1000 * 3600 * 24 * 120));
  //       }

  //       Cookies.set('CustomerId', data[0].id, { expires: expireTime, path: '/' });
  //       Cookies.set('CustomerName', data[0].name, { expires: expireTime, path: '/' });

  //       navigate('/');

  //     }
  //     else {
  //       notify('error', 'Email address or Phone Number and password is incorrect.');
  //     }
  //   }
  // }

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 text-gray-600 bg-white rounded-lg shadow-lg">
        <div className="flex justify-center">
          <img className="h-12 w-auto" src="https://alljewelz.com/wp-content/uploads/2023/01/logo.png" alt="Workflow" />
        </div>
        <h2 className="text-2xl font-bold text-center">Sign in to your account</h2>
        <Formik initialValues={{ phone_email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}>
          {({ errors, touched }) => (
            <Form className='text-left'>
              <Field
                name="phone_email"
                as={TextField}
                label="Email *"
                type="text"
                error={touched.phone_email && !!errors.phone_email}
                helperText={touched.phone_email && errors.phone_email}
                fullWidth
                margin="normal"
              />
              <Field
                name="password"
                as={TextField}
                label="Password *"
                type="password"
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                fullWidth
                margin="normal"
              />
              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" value={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                  <label htmlFor="remember-me" className="ml-2 block text-sm">Remember me</label>
                </div>

                <div className="text-sm">
                  <Link to='/change_password' className="font-medium text-indigo-600 hover:text-indigo-500">Forgot Password?</Link>
                </div>
              </div>
              <button type="submit" className="w-full py-2 mt-6 rounded-md bg-blue-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-50">
                Sign in
              </button>
              <div className="border-t border-gray-300 my-5"></div>
              <div className="text-sm text-center">
                don't have account? <Link to='/registration/sign-up' className="font-medium cursor-pointer text-indigo-600 hover:text-indigo-500">SignUp</Link>
              </div>
            </Form>)}
        </Formik>
        {/* <form className="space-y-6 pt-5" onSubmit={LogIn}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-left">Email address or Phone Number</label>
            <div className="mt-1">
              <input id="email" name="email" type="email" autoComplete="email" value={email} onChange={(e) => setEmail(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            {erremail && (<span className='text-red-500 text-left block text-sm'>{erremail}</span>)}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-left">Password</label>
            <div className="mt-1">
              <input id="password" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
            {errpassword && (<span className='text-red-500 text-left block text-sm'>{errpassword}</span>)}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" value={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
              <label htmlFor="remember-me" className="ml-2 block text-sm">Remember me</label>
            </div>

            <div className="text-sm">
              <Link to='/registration/sign-up' className="font-medium text-indigo-600 hover:text-indigo-500">Sign Up</Link>
            </div>
          </div>

          <div>
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign in</button>
          </div>
        </form> */}
      </div>
    </div>
  )
}
