export const Corporation = {
    Name: null,
    SerialNo: null,
    Email: null,
    TotalBalance: null,
    TotalDebit: null,
    TotalCredit: null,
    ContactNo: null,
    LogoURL: null,
    LogoDownloadURL: null,
    InsertTime: null,
    UpdateTime: null
}

export const Login = {
    CorporationId: null,
    username: null,
    password: null,
    InsertTime: null,
    UpdateTime: null
}

export const Slider = {
    ImageURL: null,
    ImageDownloadURL: null,
    InsertTime: null,
    UpdateTime: null
}

export const Category = {
    SerialNo: null,
    Category: null,
    ParentId: null,
    SizeIconURL: null,
    SizeIconDownloadURL: null,
    SizeVideoURL: null,
    SizeVideoDownloadURL: null,
    InsertTime: null,
    UpdateTime: null
}

export const Material = {
    SerialNo: null,
    Material: null,
    Color: null,
    Price: null
}

export const MaterialPurity = {
    Karat: null,
    SerialNo: null,
    MaterialId: null,
}

export const Diamonds = {
    SerialNo: null,
    Size: null,
    Price: null
}

export const Product = {
    ProductName: null,
    Category: null,
    Description: null,
    // MakingCharges: null,
    // ExtraMaterialCharge: null,
    MaterialDetails: [
        {
            Size: null,
            MaterialWeight: null,
            DiamondDetails: [
                {
                    Carat: null,
                    DiamondId: null,
                    Diamonds: [
                        {
                            DiamondId: null,
                            DiamondSize: null,
                            DiamondCount: null,
                        }
                    ],
                    Images: [
                        {
                            MaterialId: null,
                            Material: null,
                            Image: [
                                {
                                    ImageURL: null,
                                    DownloadURL: null
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    InsertTime: null
}

export const Customers = {
    name: null,
    email: null,
    phone_number: null,
    password: null,
    home_address1: null,
    home_address2: null,
    home_zip: null,
    home_city: null,
    home_state: null,
    home_country: null,
    office_address1: null,
    office_address2: null,
    office_zip: null,
    office_city: null,
    office_state: null,
    office_country: null,
}

export const CartItems = {
    customer_id: null,
    product_id: null,
    name: null,
    product_size: null,
    material_weight: null,
    quantity: null,
    material_id: null,
    karat_id: null,
    diamond_id: null,
    diamond_details: null,
    price: null,
    price_details: null,
    image_url: null,
    description: null,
}

export const Order = {
    item:
        [
            {
                productId: null,
                name: null,
                quantity: null,
                material_details: {
                    name: null,
                    Size: null,
                    weight: null,
                    price: null
                },
                // diamond_details: [
                //     {
                //         Size: null,
                //         quantity: null,
                //         price: null
                //     }
                // ],
                price: null,
                total_price: null,
                image_url: null,
                description: null
            }
        ],
    payment: {
        razorpay_payment_id: null,
        Amount: null,
        Status: null,
        Currency: null
    },
    order_time: null,
    last_updatetime: null,
    shipping_address: {
        Address1: null,
        Address2: null,
        City: null,
        State: null,
        Zip: null,
        Country: null
    },
    customer:
    {
        id: null,
        name: null,
        address: null
    },
    order_status: null
}

export const Configuration = {
    NewArrival: null,
    Featured: null,
    OnSale: null,
    GST: null,
    MakingCharge: null
}

export const dbCollections = {
    Corporation: 'Corporation',
    Login: 'Login',
    Slider: 'Slider',
    Category: 'Category',
    Material: 'Material',
    MaterialPurity: 'MaterialPurity',
    Product: 'Product',
    Diamonds: 'Diamonds',
    Customers: 'Customers',
    CartItems: 'CartItems',
    Order: 'Order',
    Configuration: 'Configuration',
}