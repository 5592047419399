import React, { useEffect, useState } from 'react'

import { GetSelectedItem, SelectRow } from '../../../Database/helper'
import { DataFetch, DeleteData } from '../../../Database/db_helper'

import Navbar from '../Navbar'
import Btn from '../common/btn'
import { useAlert } from '../common/message'
import { dbCollections } from '../../../Database/tables'
import { eMsg } from '../common/enum'
import ModelSlider from './Models/modelSlider'

export default function Sliders() {
  const showMsg = useAlert();

  const [model, setModel] = useState('');
  const [SliderData, setSliderData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);

  //#region Header Buttons

  const AddSlider = () => {
    setModel(<ModelSlider Slide={null} onClose={onClose} />);
  }

  const EditSlider = () => {
    const objSlider = GetSelectedItem(selectedRow, SliderData);
    if (objSlider) {
      setModel(<ModelSlider Slide={objSlider} onClose={onClose} />);
    }
    else {
      showMsg(eMsg.Info, 'No Slide is selected.');
    }
  }

  const DeleteSlider = async () => {
    const objSlider = GetSelectedItem(selectedRow, SliderData);
    if (objSlider) {
      await DeleteData(dbCollections.Slider, objSlider.id);
      showMsg(eMsg.Success, 'Slider Deleted Successfully.');
    }
    else {
      showMsg(eMsg.Info, 'No Slide is selected.');
    }
  }

  const onClose = () => {
    setModel('');
  }

  //#endregion


  //#region  Load Data

  const renderData = () => {
    return SliderData.map((Slide, Index) => (
      <tr key={Index} onClick={() => SelectRow(Index, selectedRow, setSelectedRow)} className={`bg-white border-b light:bg-gray-800 light:border-gray-700 hover:bg-blue-100 light:hover:bg-gray-600 ${selectedRow === Index ? 'grid-row-selected' : ''}`}>
        <td className="px-6 py-4"> {Index + 1} </td>
        <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap light:text-white flex">
          <img className="w-10 h-10 rounded-full" src={Slide.ImageDownloadURL} alt="Slide image" />
        </td>
      </tr>
    ));
  }

  useEffect(() => { DataFetch(dbCollections.Slider, {}, setSliderData) }, []);
  //#endregion

  return (
    <>
      <Navbar Name='Sliders' />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className='flex'>
          <Btn onClick={AddSlider} Name='Add' Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500'>
            <i className='fa fa-plus mr-1'></i>
          </Btn>
          <Btn onClick={EditSlider} Name='Edit' Class='text-white bg-purple-700 hover:bg-purple-500 focus:ring-purple-200 light:bg-purple-300 light:hover:bg-purple-500 light:focus:ring-purple-500'>
            <i className='fa fa-edit mr-1'></i>
          </Btn>
          <Btn onClick={DeleteSlider} Name='Delete' Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500'>
            <i className='fa fa-ban mr-1'></i>
          </Btn>
        </div>
        <div className='overflow-auto' style={{ height: 'calc(100vh - 14.5rem)' }}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 light:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">SrNo</th>
                <th scope="col" className="px-6 py-3">Image</th>
              </tr>
            </thead>
            <tbody>
              {renderData()}
            </tbody>
          </table>
        </div>
      </div>
      {model}
    </>
  )
}
