import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA8ueWF2S45RvCJYQs1BolVuafrMGruURQ",
    authDomain: "alljewelz.firebaseapp.com",
    projectId: "alljewelz",
    storageBucket: "alljewelz.appspot.com",
    messagingSenderId: "522670439484",
    appId: "1:522670439484:web:776d98142a985e5c101739"
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);