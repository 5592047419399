import React, { useEffect, useState } from 'react'

import { GetSelectedItem, SelectRow } from '../../../Database/helper'
import { DataFetch, DeleteData, sortByField } from '../../../Database/db_helper'

import Navbar from '../Navbar'
import Btn from '../common/btn'
import { useAlert } from '../common/message'
import { dbCollections } from '../../../Database/tables'
import { eMsg } from '../common/enum'
import ModelDiamondSize from './Models/ModelDiamondSize'

export default function Diamonds() {
    const showMsg = useAlert();

    const [model, setModel] = useState('');
    const [DiamondData, setDiamondData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [searchText, setSearchText] = useState('');

    //#region Header Buttons

    const AddSize = () => {
        setModel(<ModelDiamondSize size={null} onClose={onClose} MaxSrNo={GetMaxSrNo()} />);
    }

    const EditSize = () => {
        const objSize = GetSelectedItem(selectedRow, DiamondData);
        if (objSize) {
            setModel(<ModelDiamondSize size={objSize} onClose={onClose} MaxSrNo={GetMaxSrNo()} />);
        }
        else {
            showMsg(eMsg.Info, 'No Data is selected.');
        }
    }

    const DeleteSize = async () => {
        const objSize = GetSelectedItem(selectedRow, DiamondData);
        if (objSize) {
            await DeleteData(dbCollections.DiamondSize, objSize.id);
            showMsg(eMsg.Success, 'Diamond Size Deleted Successfully.');
        }
        else {
            showMsg(eMsg.Info, 'No Diamond Size is selected.');
        }
    }

    const onClose = () => {
        setModel('');
    }

    const GetMaxSrNo = () => {
        if (DiamondData.length > 0) {
            return DiamondData[DiamondData.length - 1].SerialNo;
        }
        else {
            return 0;
        }
    }

    //#endregion


    //#region  Load Data

    const ResetData = (dtSize) => {
        sortByField(dtSize, 'SerialNo');
        setDiamondData(dtSize);
    }

    const renderData = () => {
        return DiamondData.map((Size, Index) => (
            <tr key={Index} onClick={() => SelectRow(Index, selectedRow, setSelectedRow)} className={`bg-white border-b light:bg-gray-800 light:border-gray-700 hover:bg-blue-100 light:hover:bg-gray-600 ${selectedRow === Index ? 'grid-row-selected' : ''}`}>
                <td className="px-6 py-4"> {Size.SerialNo} </td>
                <td className="px-6 py-4"> {Size.Size} CT</td>
                <td className="px-6 py-4">$ {Size.Price}</td>
            </tr>
        ));
    }

    useEffect(() => { DataFetch(dbCollections.Diamonds, {}, ResetData) }, []);

    //#endregion

    return (
        <>
            <Navbar Name='Diamonds' onSearch={setSearchText} />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className='flex'>
                    <Btn onClick={AddSize} Name='Add' Class='text-white bg-green-700 hover:bg-green-500 focus:ring-green-200 light:bg-green-300 light:hover:bg-green-500 light:focus:ring-green-500'>
                        <i className='fa fa-plus mr-1'></i>
                    </Btn>
                    <Btn onClick={EditSize} Name='Edit' Class='text-white bg-purple-700 hover:bg-purple-500 focus:ring-purple-200 light:bg-purple-300 light:hover:bg-purple-500 light:focus:ring-purple-500'>
                        <i className='fa fa-edit mr-1'></i>
                    </Btn>
                    <Btn onClick={DeleteSize} Name='Delete' Class='text-white bg-red-700 hover:bg-red-500 focus:ring-red-200 light:bg-red-300 light:hover:bg-red-500 light:focus:ring-red-500'>
                        <i className='fa fa-ban mr-1'></i>
                    </Btn>
                </div>
                <div className='overflow-auto' style={{ height: 'calc(100vh - 14.5rem)' }}>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 light:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 light:bg-gray-700 light:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">SrNo</th>
                                <th scope="col" className="px-6 py-3">Diamond Size</th>
                                <th scope="col" className="px-6 py-3">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderData()}
                        </tbody>
                    </table>
                </div>
            </div>
            {model}
        </>
    )
}
