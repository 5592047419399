import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { DataFetch, sortByField } from '../Database/db_helper';
import { dbCollections } from '../Database/tables';

const DataContext = createContext();
export const useData = () => useContext(DataContext);

export default function Data({ children }) {

    const [lstCategory, setlstCategory] = useState([]);
    const [lstProduct, setlstProduct] = useState([]);
    const [lstMaterial, setlstMaterial] = useState([]);
    const [lstPurity, setlstPurity] = useState([]);
    const [lstDiamond, setlstDiamond] = useState([]);
    const [lstCartItems, setlstCartItems] = useState([]);
    const [objConfig, setobjConfig] = useState([]);
    const [lstWishlist, setWishlist] = useState([]);
    const [lstCustomer, setlstCustomer] = useState([]);
    const [lstOrders, setlstOrders] = useState([]);

    const setCategory = (data) => { sortByField(data, 'SerialNo'); setlstCategory(data); }
    const setMaterial = (data) => { sortByField(data, 'SerialNo'); setlstMaterial(data); }
    const setPurity = (data) => { sortByField(data, 'SerialNo'); setlstPurity(data); }
    const setDiamond = (data) => { sortByField(data, 'SerialNo'); setlstDiamond(data); }
    const setConfig = (data) => { if (data && data.length > 0) { setobjConfig(data[0]) } }
    const setlstOrder = (data) => { sortByField(data, 'SerialNo'); setlstOrders(data); }

    const ToggleWishlist = (id) => {
        const tmpWishlist = [...lstWishlist];
        const Index = tmpWishlist.findIndex(x => x === id);
        if(Index >= 0){
            tmpWishlist.splice(Index, 1);
        }
        else{
            tmpWishlist.push(id);
        }
        setWishlist(tmpWishlist);
        
        const expireTime = new Date();
        expireTime.setTime(expireTime.getTime() + (1000 * 3600 * 24 * 30));
        Cookies.set('lstWishlist', tmpWishlist, { expires: expireTime, path: '/' });
    }

    useEffect(() => {
        let tmpWishlist = Cookies.get('lstWishlist');
        if(tmpWishlist){
            tmpWishlist = tmpWishlist.split(',');
            setWishlist(tmpWishlist);
        }
    }, []);

    useEffect(() => {
        DataFetch(dbCollections.Category, {}, setCategory);
        DataFetch(dbCollections.Product, {}, setlstProduct);
        DataFetch(dbCollections.Material, {}, setMaterial);
        DataFetch(dbCollections.MaterialPurity, {}, setPurity);
        DataFetch(dbCollections.Diamonds, {}, setDiamond);
        DataFetch(dbCollections.Customers, {}, setlstCustomer);
        DataFetch(dbCollections.Configuration, {}, setConfig);
        DataFetch(dbCollections.CartItems, { customer_id: Cookies.get('CustomerId') }, setlstCartItems);
        DataFetch(dbCollections.Order, { 'customer.id': Cookies.get('CustomerId') }, setlstOrder);
    }, []);

    return (
        <DataContext.Provider value={{ lstCategory, lstProduct, lstMaterial, lstPurity, lstDiamond, lstCartItems, lstCustomer, lstOrders, objConfig, lstWishlist, ToggleWishlist }}>
            {children}
        </DataContext.Provider>
    )
}
