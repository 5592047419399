import React from 'react'

export default function Navbar({ Name, onSearch, children }) {
    return (
        <div className="flex text-left pt-2">
            <div className="flex-1 font-semibold">
                {Name && (<a className="p-0">{Name}</a>)}
                {children}
            </div>
            <div className="flex-none gap-2">
                <div className="form-control">
                    <input type="text" placeholder="Search" onChange={(e) => { onSearch(e.target.value) }} className="input input-bordered w-24 md:w-auto px-3 py-2 border rounded-md border-gray-300" />
                </div>
            </div>
        </div>
    )
}
