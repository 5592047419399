import React, { useEffect, useState } from 'react'
import ConfigDisplaysite from './Configuration/ConfigDisplaysite';
import { DataFetch, InsertData, UpdateData } from '../../Database/db_helper';
import { dbCollections, Configuration as SchemaConfiguration } from '../../Database/tables';

export default function Configuration() {

  const [CurrentTab, setCurrentTab] = useState(0);
  const [Config, setConfig] = useState();

  const updateConfig = (data) => {
    if (data && data.length > 0) {
      setConfig(data[0]);
    }
  }

  useEffect(() => {
    DataFetch(dbCollections.Configuration, {}, updateConfig);
  }, []);

  //#region Product Config

  const [makingCharge, setMakingCharge] = useState(0.00);
  const [GST, setGST] = useState(0.00);

  const GSTchangehandler = (gst) => {
    if (gst && gst >= 0) {
      setGST(parseFloat(gst));
    }
  }

  const MakingChargechangehandler = (charge) => {
    if (charge && charge >= 0) {
      setMakingCharge(parseFloat(charge));
    }
  }

  const SaveProducts = async () => {
    const tmpConfig = { ...SchemaConfiguration };
    tmpConfig.MakingCharge = makingCharge;
    tmpConfig.GST = GST;

    if (Config) {
      await UpdateData(dbCollections.Configuration, Config.id, tmpConfig);
    }
    else {
      await InsertData(dbCollections.Configuration, tmpConfig);
    }
  }

  useEffect(() => {
    if (Config) {
      setGST(Config.GST);
      setMakingCharge(Config.MakingCharge);
    }
  }, [Config]);

  //#endregion

  return (
    <div>

      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
          <li className="me-2" role="presentation">
            <button className={`inline-block p-4 z-10 rounded-t-lg hover:text-purple-700 hover:border-purple-700 hover:text-purple-700 ${CurrentTab === 0 ? 'text-purple-700 border-purple-700 border-b-2' : ' border-b-1 border-gray-700'}`} onClick={() => setCurrentTab(0)} id="Displaysite" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Displaysite</button>
          </li>
          <li className="me-2" role="presentation">
            <button className={`inline-block p-4 z-10 rounded-t-lg hover:text-purple-700 hover:border-purple-700 hover:text-purple-700 ${CurrentTab === 1 ? 'text-purple-700 border-purple-700 border-b-2' : ' border-b-1 border-gray-700'}`} onClick={() => setCurrentTab(1)} id="Product" data-tabs-target="#styled-dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">Product</button>
          </li>
        </ul>
      </div>

      <div id="default-styled-tab-content">
        <div className={`p-4 rounded-lg bg-gray-50 light:bg-gray-800 text-left ${CurrentTab === 0 ? '' : 'hidden'}`} id="styled-profile" role="tabpanel" aria-labelledby="profile-tab">
          <ConfigDisplaysite config={Config} />
        </div>
        <div className={`p-4 rounded-lg bg-gray-50 light:bg-gray-800 ${CurrentTab === 1 ? '' : 'hidden'}`} id="styled-dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <div className='flex'>
            <label className='lg:w-36 md:w-24 w-16 text-right content-center'>Making Charges (%)</label>
            <input type='text' value={makingCharge} onChange={(e) => MakingChargechangehandler(e.target.value)} className='ml-4 p-2 border border-gray-300 rounded-md w-full bg-white'></input>
          </div>
          <div className='flex mt-3'>
            <label className='lg:w-36 md:w-24 w-16 text-right content-center'>GST (%)</label>
            <input type='text' value={GST} onChange={(e) => GSTchangehandler(e.target.value)} className='ml-4 p-2 border border-gray-300 rounded-md w-full bg-white'></input>
          </div>
          <button className='mt-4 rounded-md px-4 py-1.5 border border-black bg-gray-300' onClick={() => SaveProducts()}>Save</button>
        </div>
        <div className={`p-4 rounded-lg bg-gray-50 light:bg-gray-800 ${CurrentTab === 2 ? '' : 'hidden'}`} id="styled-settings" role="tabpanel" aria-labelledby="settings-tab">
          <p className="text-sm text-gray-500 dark:text-gray-400">This is some placeholder content the <strong className="font-medium text-gray-800 dark:text-white">Settings tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling.</p>
        </div>
      </div>

    </div>
  )
}
