import React, { useEffect, useState } from 'react'

import { Category, dbCollections } from '../../../../Database/tables'

import Btn from '../../common/btn'
import { GetDownloadURL, GetTime, InsertData, UpdateData, UploadFile } from '../../../../Database/db_helper'
import { useAlert } from '../../common/message'
import { IsEmpty, IsNegativeNum } from '../../../../Database/validation'
import { eMsg } from '../../common/enum'

export default function ModelCategory({ category, ParentId, MaxSrNo, onClose }) {
    const showMsg = useAlert();

    const [CategoryName, setCategoryName] = useState('');
    const [SerialNo, setSerialNo] = useState('0');
    const [SizeVideo, setSizeVideo] = useState();
    const [SizeImage, setSizeImage] = useState();
    const [SizeImageURL, setSizeImageURL] = useState();

    const IsValid = () => {
        if (IsEmpty(CategoryName)) {
            showMsg(eMsg.Info, 'Please Category Name.');
            return false;
        }

        if (IsEmpty(SerialNo)) {
            showMsg(eMsg.Info, 'Please Enter SerialNo');
            return false;
        }
        else if (IsNegativeNum(SerialNo)) {
            showMsg(eMsg.Info, 'SerialNo must be positive number.');
            return false;
        }

        return true;
    }

    const SaveData = async () => {
        if (!IsValid()) {
            return;
        }

        const objCategory = { ...Category };
        objCategory.Category = CategoryName;
        objCategory.SerialNo = SerialNo;
        objCategory.UpdateTime = await GetTime();

        if (SizeImage) {
            const URL = await UploadFile(SizeImage, 'images/Category');
            if (URL) {
                const DownloadURL = await GetDownloadURL(URL);
                if (DownloadURL) {
                    objCategory.SizeIconURL = URL;
                    objCategory.SizeIconDownloadURL = DownloadURL;
                }
            }
        }

        if (SizeVideo) {
            const URL = await UploadFile(SizeVideo, 'Videos/Category');
            if (URL) {
                const DownloadURL = await GetDownloadURL(URL);
                if (DownloadURL) {
                    objCategory.SizeVideoURL = URL;
                    objCategory.SizeVideoDownloadURL = DownloadURL;
                }
            }
        }

        if (category) {
            await UpdateData(dbCollections.Category, category.id, objCategory);
            showMsg(eMsg.Success, 'Category Updated Successfully.');
        }
        else {
            objCategory.ParentId = ParentId;
            objCategory.InsertTime = await GetTime();

            await InsertData(dbCollections.Category, objCategory);
            showMsg(eMsg.Success, 'Category Inserted Successfully.');
        }

        onClose();
    }

    useEffect(() => {
        if (category) {
            setSizeImageURL(category.SizeIconDownloadURL);
            setCategoryName(category.Category);
            setSerialNo(category.SerialNo);
        }
        else {
            setSerialNo(MaxSrNo + 1);
        }
    }, []);

    return (
        <div tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 max-h-full" style={{ backgroundColor: 'rgba(135, 135, 135, 0.50)' }}>
            <div className="relative p-4 w-full max-w-xl max-h-full mx-auto my-auto">
                <div className="relative bg-white rounded-lg shadow light:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t light:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                            Create New Category
                        </h3>
                        <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5 text-left">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className='col-span-2'>
                                <label htmlFor="Image" className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Icon</label>
                                <input type="file" name="Image" onChange={(e) => setSizeImage(e.target.files[0])} accept='image/*' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" />
                                {SizeImageURL && (<img src={SizeImageURL} className='w-44 h-44'></img>)}
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Category</label>
                                <input type="text" name="name" value={CategoryName} onChange={(e) => setCategoryName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Type Caterory Name..." />
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 light:text-white">SerialNo</label>
                                <input type="number" name="name" value={SerialNo} onChange={(e) => setSerialNo(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Type Serial Number..." />
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="Video" className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Size Video</label>
                                <input type="file" name="Video" onChange={(e) => setSizeVideo(e.target.files[0])} accept='video/*' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" />
                            </div>
                        </div>
                        <Btn Name='Save' onClick={SaveData} Class='text-white bg-blue-700 hover:bg-blue-800 light:bg-blue-600 light:hover:bg-blue-700 light:focus:ring-blue-800' />
                        <Btn Name='Cancel' onClick={onClose} Class='text-white bg-gray-500 hover:bg-gray-600 light:bg-gray-400 light:hover:bg-gray-500 light:focus:ring-gray-600' />
                    </div>
                </div>
            </div>
        </div>
    )
}
