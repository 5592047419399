import React, { useState } from 'react';

export default function Btn({ Class, Name, onClick, children }) {

    const [IsLoad, setIsLoad] = useState(false);

    const btnClick = async () => {
        setIsLoad(true);
        try {
            await onClick();
            setIsLoad(false);
        }
        catch {
            setIsLoad(false);
        }
    }
    
    return (
        <button className={`${Class} font-medium rounded-lg text-sm px-4 py-2.5 me-3 mb-4`} onClick={() => btnClick()} disabled={IsLoad}>
            {IsLoad ? (<i className='fa fa-circle-o-notch fa-spin mr-2'></i>) : (children)}
            {Name}
        </button>
    )
}