import React, { useEffect, useState } from 'react'

import { Material, dbCollections } from '../../../../Database/tables'

import Btn from '../../common/btn'
import { InsertData, UpdateData } from '../../../../Database/db_helper'
import { useAlert } from '../../common/message'
import { IsEmpty, IsNegativeNum } from '../../../../Database/validation'
import { eMsg } from '../../common/enum'

export default function ModelMaterial({ material, MaxSrNo, onClose }) {
    const showMsg = useAlert();

    const [MaterialName, setMaterialName] = useState('');
    const [SerialNo, setSerialNo] = useState('0');
    const [Color, setColor] = useState('#ffffff');
    const [Price, setPrice] = useState('0');

    const IsValid = () => {
        if (IsEmpty(MaterialName)) {
            showMsg(eMsg.Info, 'Please Enter Material Name.');
            return false;
        }

        if (IsEmpty(SerialNo)) {
            showMsg(eMsg.Info, 'Please Enter SerialNo');
            return false;
        }
        else if (IsNegativeNum(SerialNo)) {
            showMsg(eMsg.Info, 'SerialNo must be positive number.');
            return false;
        }

        if (IsEmpty(Color)) {
            showMsg(eMsg.Info, 'Please Select Color.');
        }

        if (IsEmpty(Price)) {
            showMsg(eMsg.Info, 'Please Enter Price');
            return false;
        }
        else if (IsNegativeNum(Price)) {
            showMsg(eMsg.Info, 'Price must be positive number.');
            return false;
        }

        return true;
    }

    const SaveData = async () => {
        if (!IsValid()) {
            return;
        }

        const objMaterial = { ...Material };
        objMaterial.Material = MaterialName;
        objMaterial.SerialNo = SerialNo;
        objMaterial.Color = Color;
        objMaterial.Price = Price;

        if (material) {
            await UpdateData(dbCollections.Material, material.id, objMaterial);
            showMsg(eMsg.Success, 'Material Updated Successfully.');
        }
        else {
            await InsertData(dbCollections.Material, objMaterial);
            showMsg(eMsg.Success, 'Material Inserted Successfully.');
        }

        onClose();
    }

    useEffect(() => {
        if (material) {
            setMaterialName(material.Material);
            setSerialNo(material.SerialNo);
            setColor(material.Color);
            setPrice(material.Price);
        }
        else {
            setSerialNo(MaxSrNo + 1);
        }
    }, []);

    return (
        <div tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 max-h-full" style={{ backgroundColor: 'rgba(135, 135, 135, 0.50)' }}>
            <div className="relative p-4 w-full max-w-xl max-h-full mx-auto my-auto">
                <div className="relative bg-white rounded-lg shadow light:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t light:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 light:text-white">
                            Create New Material
                        </h3>
                        <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5 text-left">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Material Name</label>
                                <input type="text" value={MaterialName} onChange={(e) => setMaterialName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Type Material Name..." />
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">SerialNo</label>
                                <input type="number" value={SerialNo} onChange={(e) => setSerialNo(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Type Serial number..." />
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Material Color</label>
                                <input type="color" value={Color} onChange={(e) => setColor(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block h-10 w-full p-2.5 light:bg-gray-600 light:border-gray-500 light:placeholder-gray-400 light:text-white light:focus:ring-primary-500 light:focus:border-primary-500" placeholder="Select Material Color..." />
                            </div>
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 light:text-white">Price</label>
                                <label className="input input-bordered flex items-center gap-2">
                                    $
                                    <input type="number" value={Price} onChange={(e) => setPrice(e.target.value)} className="grow" placeholder="Enter Material Price..." />
                                </label>
                            </div>
                        </div>
                        <Btn Name='Save' onClick={SaveData} Class='text-white bg-blue-700 hover:bg-blue-800 light:bg-blue-600 light:hover:bg-blue-700 light:focus:ring-blue-800' />
                        <Btn Name='Cancel' onClick={onClose} Class='text-white bg-gray-500 hover:bg-gray-600 light:bg-gray-400 light:hover:bg-gray-500 light:focus:ring-gray-600' />
                    </div>
                </div>
            </div>
        </div>
    )
}
