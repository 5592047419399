import React, { useEffect, useState } from 'react';
import Item from './Item';
import { useData } from '../../Data';

export default function Product({ selectedCategories }) {
  const { lstProduct, lstCategory } = useData();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [lstCategoryItems, setlstCategoryItems] = useState([]);
  const itemsPerPage = 12;

  useEffect(() => {
    // Calculate the start and end index of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
    const tmplstCategoryItems = lstProduct.filter((item) => checkCategoryIsSelected(item.Category));
    setlstCategoryItems(tmplstCategoryItems);
    const lstcurrentItems = tmplstCategoryItems.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(lstcurrentItems);
  }, [currentPage, selectedCategories]);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const checkCategoryIsSelected = (ProductCategoryId) => {
    if (selectedCategories && selectedCategories.length > 0) {
      // Check if product category includes in selected category ID
      let category = selectedCategories.find(category => category === ProductCategoryId);
      if (category) {
        return true;
      }
      else {
        let objCategory = lstCategory.find(category => category.id === ProductCategoryId);
        if (objCategory) {
          return checkCategoryIsSelected(objCategory.ParentId);
        }
        else {
          return false;
        }
      }
    }
    else {
      return true
    }
  }

  return (
    <div id="primary" className="pls-content-area col-12 col-md-8 col-lg-9 col-xl-9">
      <header className="woocommerce-products-header">
      </header>
      <div className="woocommerce-notices-wrapper"></div>
      <div className="products products-wrap product-style-1 row grid-view grid-col-lg-3 grid-col-md-2 grid-col-2 d-flex flex-wrap">
        {currentItems.map((item, index) => (
          <div key={item.id} className='col-lg-4 col-md-6 col-sm-12 col-xs-12 p-2'>
            <Item product={item} />
          </div>
        ))}
      </div>
      <div className="mt-16 mb-10">
        {/* Paging */}
        <ul className="text-center w-fit mx-auto flex">
          <li>
            <a className={`px-3 py-3 ml-2 hover:bg-black hover:text-white ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage - 1)} >
              <span className='fa fa-angle-left'></span>
            </a>
          </li>
          {/* Render page numbers */}
          {Array.from({ length: Math.ceil(lstCategoryItems.length / itemsPerPage) }, (_, i) => i + 1).map(
            (pageNumber) => (
              <li key={pageNumber}>
                <a className={`px-5 py-3 ml-2 hover:bg-black hover:text-white border-1 border-black ${currentPage === pageNumber ? 'bg-black text-white' : ''}`} onClick={() => handlePageChange(pageNumber)} >
                  {pageNumber}
                </a>
              </li>
            )
          )}
          <li>
            <a className={`px-3 py-3 ml-2 hover:bg-black hover:text-white ${currentPage === Math.ceil(lstProduct.length / itemsPerPage) ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage + 1)} >
              <span className='fa fa-angle-right'></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}