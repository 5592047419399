import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import './CSS/admin.css';
import Message from './components/common/message';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

import Dashboard from './components/Dashboard';
import Slider from './components/Displaysite/Sliders';
import Products from './components/ProductManage/Products';
import DiamondDetail from './components/ProductManage/DiamondDetail';
import Categories from './components/InventoryManage/Categories';
import Material from './components/InventoryManage/Material';
import Purity from './components/InventoryManage/Purity';
import Configuration from './components/Configuration';
import Orders from './components/ProductManage/Orders';
import Diamonds from './components/InventoryManage/Diamonds';

export default function AdminPanel() {

  const navigate = useNavigate();
  useEffect(() => {
    const IsAdmin = Cookies.get('IsAdmin');
    if (!IsAdmin) {
      navigate('/');
    }
  }, []);
  return (
    <>
      <Message>
        <Header />
        <Sidebar />
        <div className="p-4 xl:ml-64">
          <div className="p-4 rounded-lg light:border-gray-700 mt-12">
            <Routes>
              <Route path='/' element={<Dashboard />} />
              <Route path='/slider' element={<Slider />} />
              <Route path='/products' >
                <Route path='' element={<Products />} />
                <Route path='diamond_detail' element={<DiamondDetail />} />
              </Route>
              <Route path='/category' element={<Categories />} />
              <Route path='/orders' element={<Orders />} />
              <Route path='/material' >
                <Route path='' element={<Material />} />
                <Route path='purity' element={<Purity />} />
              </Route>
              <Route path='/diamonds' element={<Diamonds />} />
              <Route path='/config' element={<Configuration />} />
            </Routes>
          </div>
        </div>
      </Message>
    </>
  )
}