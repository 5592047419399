import React, { useEffect, useState } from 'react';

import Item from '../Shop/Item';
import { useData } from '../../Data';

export default function Products() {
  const { lstProduct, objConfig } = useData();

  const [currentTab, setCurrentTab] = useState(0);
  const [Products, setProducts] = useState([]);

  useEffect(() => {
    if (objConfig && lstProduct) {
      if (currentTab === 0 && objConfig.NewArrival) {
        const tmplstArrivals = lstProduct.filter(item => objConfig.NewArrival.includes(item.id));
        setProducts(tmplstArrivals);
      }
      else if (currentTab === 1 && objConfig.Featured) {
        const tmplstFeatured = lstProduct.filter(item => objConfig.Featured.includes(item.id));
        setProducts(tmplstFeatured);
      }
      else if (currentTab === 2 && objConfig.OnSale) {
        const tmplstOnSale = lstProduct.filter(item => objConfig.OnSale.includes(item.id));
        setProducts(tmplstOnSale);
      }
    }

  }, [currentTab, objConfig]);

  return (
    <div id="primary" className="pls-content-area md:p-18 sm:p-12 xs:p-5">
      <ul className="flex whitespace-nowrap mb-px w-fit mx-auto">
        <li>
          <h2>
            <a onClick={() => setCurrentTab(0)} className={`flex leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px  hover:border-black hover:text-black hover:border-slate-700 ${currentTab === 0 ? 'border-current text-black  text-md' : 'border-transparant text-gray-400  text-sm'}`}>NEW ARRIVED</a>
          </h2>
        </li>
        <li className='ml-8'>
          <h2>
            <a onClick={() => setCurrentTab(1)} className={`flex text-md leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px hover:border-black hover:text-black hover:border-slate-700 ${currentTab === 1 ? 'border-current text-black text-md' : 'border-transparant text-gray-400 text-sm'}`}>FEATURED</a>
          </h2>
        </li>
        <li className='ml-8'>
          <h2>
            <a onClick={() => setCurrentTab(2)} className={`flex text-md leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px hover:border-black hover:text-black hover:border-slate-700 ${currentTab === 2 ? 'border-current text-black text-md' : 'border-transparant text-gray-400 text-sm'}`}>ON A SALE</a>
          </h2>
        </li>
      </ul>
      <div className='grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2 md:gap-4 mt-4 md:mt-8'>
        {Products.map((product) => (<div key={product.id} className=''><Item product={product} /></div>))}
      </div>
    </div>
  )
}