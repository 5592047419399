import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <div className='w-64 border-r border-gray-300 text-left'>
        <Link className='block w-full py-2' to='dashboard'><i className='fa fa-home !w-8'></i>Dashboard</Link>
        <Link className='block w-full py-2' to='profile'><i className='fa fa-users !w-8'></i>Profile</Link>
        <Link className='block w-full py-2' to='cart'><i className='fa fa-shopping-cart !w-8'></i>Cart</Link>
        <Link className='block w-full py-2' to='orders'><i className='fa fa-shopping-bag !w-8'></i>Orders</Link>
    </div>
  )
}
